export const t = {
    search: {
        placeholder: 'Search among my communities',
        'no-results': {
            title: 'No results for ',
            description: 'Try another search or expanding your search to include all the communities available on Zealy.',
            cta: 'Expand my search',
        },
    },
    'create-community': 'Create new community',
    explore: 'Discover communities',
};
export const communities = [
    {
        id: '99d8aedb-06e4-4ae2-bb68-0d1668796ab2',
        name: 'Lido Beach Community',
        subdomain: 'lido-beach',
        image: 'https://crew3-development.s3.eu-west-3.amazonaws.com/public/wpli-qju3jkialpjzmigkdlf0fznvt5i-logo.png',
    },
    {
        id: '7f10bd2c-f35f-4433-b4d4-bbeaf12c88e9',
        name: 'Cosy Roommates',
        subdomain: 'cosy-roommates',
        image: 'https://crew3-development.s3.eu-west-3.amazonaws.com/public/7f10bd2c-f35f-4433-b4d4-bbeaf12c88e9-profile.png',
    },
    {
        id: 'c2d46699-1c6f-4843-af7a-bc40e0695f22',
        name: 'FFTE Community',
        subdomain: 'ffte-community',
        image: '',
    },
    {
        id: 'b885f167-6d13-4a1c-b6a7-a6bc56d76325',
        name: 'The Art Room',
        subdomain: 'the-art-room',
        image: 'https://crew3-development.s3.eu-west-3.amazonaws.com/public/s54o3slyukjeq7lgaxzwnfwyy3ug-mp4-logo.png',
    },
    {
        id: '2f622288-c5c3-4dba-a5df-af3099643dd9',
        name: 'Roaming Nomads',
        subdomain: 'roaming-nomads',
        image: 'https://crew3-development.s3.eu-west-3.amazonaws.com/public/jk8eejnzdtzxil2d6j8raea5-ytul2-y-logo.png',
    },
    {
        id: '35c26cfe-fad5-4e14-aa4f-1aa34f7da9ae',
        name: 'Tech Retreat',
        subdomain: 'tech-retreat',
        image: '',
    },
    {
        id: '3782bca5-f1a1-49d3-86a5-00764deecc57',
        name: 'Innovation Hub',
        subdomain: 'innovation-hub',
        image: 'https://crew3-development.s3.eu-west-3.amazonaws.com/public/3782bca5-f1a1-49d3-86a5-00764deecc57-profile.png',
    },
    {
        id: '14bfa175-d819-421f-90e5-cca17778daa4',
        name: 'Creative Minds',
        subdomain: 'creative-minds',
        image: 'https://crew3-development.s3.eu-west-3.amazonaws.com/public/mzycu1vpek-esonpno7dc-gpgvjju4vc-logo.png',
    },
    {
        id: 'bc8666b8-277c-44c9-a02b-4949857622dc',
        name: 'Tech Innovators',
        subdomain: 'tech-innovators',
        image: 'https://crew3-development.s3.eu-west-3.amazonaws.com/public/lijjp9oa6el7nbrw32gntufybblrzcyz-logo.png',
    },
    {
        id: '01157b61-9cb8-4c09-83b0-7e6e3ef6d69a',
        name: 'Beta Testers',
        subdomain: 'beta-testers',
        image: 'https://crew3-development.s3.eu-west-3.amazonaws.com/public/fsvmn4e80dejdumniht2smmuuqpwlpob-logo.png',
    },
    {
        id: 'abcf7138-f8c2-43f3-a82e-49001bb8e506',
        name: 'Room for Artists',
        subdomain: 'room-for-artists',
        image: 'https://crew3-development.s3.eu-west-3.amazonaws.com/public/a7i_dsg5n-p5tq-41rh5yn5c6j_1xgoh-logo.png',
    },
    {
        id: 'e65842a3-18fe-43f1-9271-ccc3e77db4c1',
        name: 'Tech Explorers',
        subdomain: 'tech-explorers',
        image: 'https://crew3-development.s3.eu-west-3.amazonaws.com/public/mlvwqeeccnol2txomobba_iogeu0xgde-logo.png',
    },
    {
        id: '91ab82d0-3a18-4e73-baea-031cd4ac45be',
        name: 'Innovative Creators',
        subdomain: 'innovative-creators',
        image: 'https://crew3-development.s3.eu-west-3.amazonaws.com/public/zp6tug2hddzv_eibzysef950vzqyz2pm-logo.png',
    },
];
