import { Tooltip, TooltipContent, TooltipTrigger } from '@zealy/design-system';

export interface FpsChartProps {
  fps: Array<number>;
  gcTimeInS: number;
}

const BaseHeight = 24;
const MaxFps = 60;
const BarChartWidth = 4;

export const FpsChart = ({ fps, gcTimeInS }: FpsChartProps) => {
  return (
    <div className="flex flex-row items-end" style={{ width: BarChartWidth * gcTimeInS }}>
      {fps.map((f, index: number) => {
        const hypotheticalHeight = Math.round((f / MaxFps) * BaseHeight);
        const barHeight = Math.min(hypotheticalHeight, 60);

        return (
          <Tooltip key={index}>
            <TooltipTrigger asChild>
              <div
                className="relative flex flex-col justify-end group/fps hover:bg-tertiary"
                style={{ height: 24, width: 4 }}
              >
                <div className="bg-brand-secondary" style={{ height: barHeight }} />
              </div>
            </TooltipTrigger>
            <TooltipContent>{f}fps</TooltipContent>
          </Tooltip>
        );
      })}
    </div>
  );
};
