'use client';

import React, { useEffect } from 'react';
import { useCookie } from 'react-use';

import { Popup } from '@zealy/design-system';
import { useAuthenticatedUser, useCommunity } from '@zealy/queries';
import { JSONSafeParse, roleIsAtLeast, UserRole } from '@zealy/utils';

import { useCommunitiesFlags } from '#context/FeatureFlags';
import { useDidMount } from '#hooks/useDidMount';

import { usePopup } from '../PopupProvider';

interface FlagValue {
  title: string;
  description?: string;
  cta?: string;
  img?: string;
  link?: string;
  role?: UserRole;
}

export const BookCall = () => {
  const user = useAuthenticatedUser();
  const { book_call } = useCommunitiesFlags(['book_call']);

  const { isOpen, onOpenChange } = usePopup({
    id: 'book_call',
    priority: 5,
  });

  const didMount = useDidMount();

  const community = useCommunity();

  const [seenFlag, setSeenFlag] = useCookie(`popup:book_call`);

  const { title, description, cta, img, link, role } = JSONSafeParse(
    (book_call.value as string) || '{}',
  ) as FlagValue;

  const showPopup =
    user.data &&
    didMount &&
    !seenFlag &&
    book_call.enabled &&
    !(role && 'role' in user.data && !roleIsAtLeast(user.data?.role, role)) &&
    Number(community.data?.totalMembers) > 50;

  useEffect(() => {
    if (showPopup) {
      onOpenChange(true);
    }
    return () => onOpenChange(false);
  }, [showPopup, onOpenChange]);

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setSeenFlag('true', {
        expires: 60,
      });
      onOpenChange(open);
    }
  };

  const onClick = () => {
    handleOpenChange(false);
    if (link) window.open(link, '_blank');
  };

  return (
    <Popup
      open={isOpen}
      onOpenChange={handleOpenChange}
      {...{ title, description, cta, img, link, onClick }}
      contentProps={{
        onPointerDownOutside: e => e.preventDefault(),
      }}
    />
  );
};
