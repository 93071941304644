'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { kFormatter } from '@zealy/utils';
import { cn } from '../../utils/cn';
import { Badge } from '../Badge/Badge';
import { Button } from '../Button/Button';
import { ProgressBarAnatomy as P } from '../ProgressBar/ProgressBar.anatomy';
export const PlanLimit = ({ className, plan, title, cta, currentValue = 0, maxValue, ResetLimitMessage, LimitExceededMessage, onClickCTA, additionalDetails, unlisted, ...props }) => {
    const nearLimit = maxValue ? currentValue >= maxValue * 0.85 : false;
    const currentValueFormatted = currentValue >= 10_000 ? kFormatter(currentValue) : currentValue;
    return (_jsxs("div", { className: cn(['bg-tertiary rounded-3xl flex flex-col items-start py-150 px-200 gap-150'], className), ...props, children: [_jsx(Badge, { size: 'xs', children: plan }), additionalDetails, !!maxValue && (_jsxs(_Fragment, { children: [_jsxs(P.Root, { "data-testid": "progressbar", fillShadowBg: "var(--color-bg-tertiary)", variant: 'neutral', size: 'sm', max: maxValue, value: currentValue, children: [_jsxs(P.LabelGroup, { children: [_jsx(P.Label, { "data-testid": "userlevel", children: _jsx("span", { className: "text-primary text-xs", children: title }) }), _jsxs(P.Label, { "data-testid": "userxp", className: cn(nearLimit ? 'text-error-tertiary' : null), children: [currentValueFormatted, " / ", kFormatter(maxValue)] })] }), _jsx(P.Track, { ...(nearLimit && { trackColor: 'var(--color-text-error-tertiary)' }) })] }), _jsx("span", { className: 'text-tertiary body-component-sm', children: !nearLimit ? ResetLimitMessage : LimitExceededMessage })] })), _jsx(Button, { size: "xs", className: "w-full", variant: 'muted', onClick: onClickCTA, children: cta })] }));
};
