'use client';

import { useEffect } from 'react';
import { useCookie } from 'react-use';
import { useParams, usePathname } from 'next/navigation';

import { Feedback, Popup } from '@zealy/design-system';
import { useAuthenticatedUser } from '@zealy/queries';
import { roleIsAtLeast, UserRole } from '@zealy/utils';

import { useEventTracker } from '#context';
import { useCommunitiesFlags } from '#context/FeatureFlags';

import { usePopup } from './PopupProvider';

interface FlagValue {
  feature: string;
  question: string;
  pathname: string;
  role?: UserRole;
  delayInMinutes?: number;
}

export const FeatureFlagFeedback = ({ feature }: { feature: string }) => {
  const { isOpen, onOpenChange } = usePopup({
    id: 'feedback',
    priority: 1,
  });

  const flags = useCommunitiesFlags([`feedback-${feature}`]);
  const user = useAuthenticatedUser();
  const path = usePathname();
  const { analytics } = useEventTracker();
  const { subdomain } = useParams();

  const { enabled, value } = flags[`feedback-${feature}`];

  const { question, pathname, role, delayInMinutes } = JSON.parse(
    (value as string) || '{}',
  ) as FlagValue;

  const [seenFlag, setSeenFlag] = useCookie(`feedback:${feature}`);

  const containsPath = pathname && path.includes(pathname);

  const shouldHidePopup =
    !enabled ||
    !!seenFlag ||
    !value ||
    !!(role && user.data && 'role' in user.data && !roleIsAtLeast(user.data?.role, role)) ||
    !containsPath;

  useEffect(() => {
    if (shouldHidePopup) return;

    const delayInMs = delayInMinutes ? delayInMinutes * 60 * 1000 : 600000;

    const timeOut = setTimeout(() => onOpenChange(true), delayInMs);

    return () => {
      clearTimeout(timeOut);
      onOpenChange(false);
    };
  }, [shouldHidePopup, onOpenChange, delayInMinutes]);

  const setSeen = () => {
    setSeenFlag('true', {
      expires: 60,
    });
    onOpenChange(false);
  };

  const handleSubmit = (data: any) => {
    analytics.track('Feature feedback', {
      page: window.location.pathname,
      feature: feature,
      question,
      subdomain,
      role: user.data && 'role' in user.data ? user.data.role : undefined,
      ...data,
    });

    setSeen();
  };

  return (
    <Popup
      className="dark"
      open={isOpen}
      onOpenChange={setSeen}
      contentProps={{
        onPointerDownOutside: e => e.preventDefault(),
      }}
    >
      <Feedback
        title={question}
        className="border-transparent p-0 pt-200"
        onSubmit={handleSubmit}
      />
    </Popup>
  );
};
