import { envConfig } from '#app/config';

export type Environment = 'production' | 'staging' | 'development' | 'testing';

export const TWITTER_HOMEPAGE = 'https://x.com';

function getDomainSuffix() {
  const env = envConfig.env as Environment;
  switch (env) {
    case 'development':
      return 'dev';
    case 'staging':
      return 'staging';
    default:
      return '';
  }
}

/**
 * Return base domain depending of node env
 */
export function getBaseDomain() {
  const suffix = getDomainSuffix();
  return `zealy${suffix}.io`;
}

export function getSubdomainURL(subdomain: string, shouldIncludeProtocol?: boolean) {
  const domain = getBaseDomain();
  const HTTPS_PROTOCOL = 'https://';
  const protocol = shouldIncludeProtocol ? HTTPS_PROTOCOL : '';
  return `${protocol}${subdomain}.${domain}`;
}

export const getTwitterAccountURL = (username: string) => `${TWITTER_HOMEPAGE}/${username}`;

// Return the URL of a tweet,
// Twitter/X allow access to tweet with random user handle, only the tweet id matters
export const getTweetURL = (id: string, accountHandle: string = 'foo') =>
  `${TWITTER_HOMEPAGE}/${accountHandle}/status/${id}`;
