import { cva } from 'class-variance-authority';
const root = cva([
    'mt-0 relative',
    'box-border',
    'w-full',
    'bg-text-field',
    'border-text-field',
    'hover:bg-text-field-hover',
    'hover:border-text-field-hover',
    'focus-within:bg-text-field-focus',
    'focus-within:outline-none',
    'focus-within:ring-text-field-focus',
    'focus-within:!ring-1',
    'focus-within:ring-inset',
    'transition-colors duration-75 ease-out',
    'autofill:!bg-text-field autofill:shadow-none',
    'text-text-field-content',
], {
    variants: {
        state: {
            default: 'focus-within:ring-text-field-focus focus-within:border-info-focus focus-within:hover:border-info-focus',
            error: 'focus-within:ring-text-field-focus-error focus-within:border-error-focus focus-within:hover:border-error-focus',
            success: 'focus-within:ring-text-field-focus-success focus-within:border-success-focus focus-within:hover:border-success-focus',
            disabled: 'pointer-events-none cursor-not-allowed border-text-field-disabled bg-text-field-disabled',
        },
        size: {
            sm: 'rounded-text-field-sm gap-text-field-sm-x',
            md: 'rounded-text-field-md gap-text-field-md-x',
            lg: 'rounded-text-field-lg gap-text-field-lg-x',
            xl: 'rounded-text-field-xl gap-text-field-xl-x',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
const input = cva(['bg-transparent outline-none border-none h-full w-full'], {
    variants: {
        state: {
            default: 'placeholder:text-text-field-placeholder text-text-field-content',
            error: 'placeholder:text-text-field-placeholder text-text-field-content',
            success: 'placeholder:text-text-field-placeholder text-text-field-content',
            disabled: 'text-text-field-disabled placeholder:text-text-field-disabled',
        },
        size: {
            sm: 'py-text-field-sm-y px-text-field-sm-x text-field-text-sm',
            md: 'py-text-field-md-y px-text-field-md-x text-field-text-md',
            lg: 'py-text-field-lg-y px-text-field-lg-x text-field-text-lg',
            xl: 'py-text-field-xl-y px-text-field-xl-x text-field-text-xl',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
export const styles = {
    root,
    input,
};
