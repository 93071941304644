'use client';

import { FlagsmithProvider, useFlags } from 'flagsmith/react';
import React from 'react';

import { getMe } from '@zealy/queries';

import { createContext } from '../createContext';
import { communitiesFlag, init } from './communitiesFlag';

export type CommunitiesFlagContext = typeof communitiesFlag;

export const [CommunitiesFlagsContextProvider, useCommunitiesFlagContext] =
  createContext<CommunitiesFlagContext>({
    name: 'CommunitiesFlagsProvider',
    hookName: 'useCommunitiesFlag',
    defaultValue: communitiesFlag,
  });

export function CommunitiesFlagProvider({ children }: { children: React.ReactNode }) {
  return (
    <FlagsmithProvider flagsmith={communitiesFlag} serverState={communitiesFlag.getState()}>
      <>{children}</>
    </FlagsmithProvider>
  );
}

export function useCommunitiesFlags<T extends string[]>(key: T) {
  return useFlags(key);
}

/**
 * Helper for parsing array of user ids from flag value (JSON), for emulating user-related flag
 */
export function useUserFlag(key: string) {
  const flag = useFlags([key])?.[key];
  if (!flag) {
    return { enabled: false };
  }
  if (flag.enabled) {
    return { enabled: true };
  }
  const userIds: string[] = flag.value ? JSON.parse(flag.value as string) : [];
  const myUserId = getMe();
  if (!myUserId) {
    return { enabled: false };
  }
  return { enabled: userIds.includes(myUserId) };
}
