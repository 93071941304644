import { useState } from 'react';
import { useTranslations } from 'next-intl';

import { Button, TagAnatomy, TextArea } from '@zealy/design-system';

export interface NPSFormProps {
  onSubmit: (score: number, comment: string) => void;
}

export const NPSForm = ({ onSubmit }: NPSFormProps) => {
  const t = useTranslations('nps');
  const [score, setScore] = useState<number | null>(null);
  const [comment, setComment] = useState<string>('');

  const steps = Array.from(
    {
      length: 11,
    },
    (_, idx) => idx,
  );

  const handleSubmit = () => score !== null && onSubmit(score, comment);

  return (
    <div className="flex flex-col gap-200">
      <p className="body-paragraph-lg-bold text-primary min-w-0">{t('title')}</p>

      <div className="min-w-0 w-full">
        <div className="flex gap-100 min-w-0 flex-wrap md:flex-nowrap w-full">
          {steps?.map(step => (
            <TagAnatomy.Root
              key={step}
              className="!px-0 min-w-tag-md w-[40px]"
              size="md"
              onClick={() => setScore(step)}
              selected={score === step}
            >
              <TagAnatomy.Label className="!text-center">{step}</TagAnatomy.Label>
            </TagAnatomy.Root>
          ))}
        </div>

        <div className="flex justify-between mt-100">
          <p className="body-paragraph-md text-secondary">{t('start')}</p>
          <p className="body-paragraph-md text-secondary">{t('end')}</p>
        </div>
      </div>
      {score !== null && (
        <>
          <TextArea
            name="comment"
            placeholder={t('placeholder')}
            onChange={e => setComment(e.target.value)}
            value={comment}
          />
          <Button className="self-end" onClick={handleSubmit}>
            {t('submit')}
          </Button>
        </>
      )}
    </div>
  );
};
