'use client';

import React from 'react';
import Link from 'next/link';

import { Button } from '@zealy/design-system';
import { Community, CommunityMember } from '@zealy/queries';

import { CommunityProfile } from '#components/CommunityProfile';
import { ModalProps } from '#components/Modal';
import { ResponsivePopover } from '#components/ResponsivePopover';
import { useIsMobile } from '#hooks/useIsMobile';
import { cn } from '#utils/utils';

import { useCommunityActions } from './CommunityActions.hooks';

export interface CommunityInfoProps extends ModalProps {
  community: Community;
  user?: CommunityMember;
}

const LinkWrapper = ({ children, href }: { children: React.ReactNode; href?: string | null }) =>
  href ? (
    <Link href={href} prefetch={false}>
      {children}
    </Link>
  ) : (
    children
  );

export const CommunityInfo = ({ community, user, ...props }: CommunityInfoProps) => {
  const isMobile = useIsMobile();
  const actions = useCommunityActions({ community, user }).flatMap(group => group.items);

  return (
    <ResponsivePopover {...props} asModal>
      <CommunityProfile community={community} />
      <div className="flex flex-col gap-100 mt-300">
        {isMobile &&
          actions.map(
            action =>
              action.key !== 'community-information' && (
                <LinkWrapper key={action.key} href={action.link}>
                  <Button
                    variant="muted"
                    leftIcon={action.icon}
                    onClick={action.onClick}
                    className={cn('w-full', action.type === 'destructive' && 'text-error-primary')}
                  >
                    {action.label}
                  </Button>
                </LinkWrapper>
              ),
          )}
      </div>
    </ResponsivePopover>
  );
};
