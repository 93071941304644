import { jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement } from 'react';
import { cn } from '../../utils/cn';
import { iconStyles, linkStyles } from './Link.styles';
export const Link = ({ as: Component = 'a', children, leftIcon, rightIcon, size, color, onlyIcon, className, underlined, isExternal, ...props }) => {
    const iconClassName = iconStyles({ size });
    return (_jsxs(Component, { className: cn(linkStyles({
            size,
            color,
            onlyIcon,
            underlined,
        }), className), target: isExternal ? '_blank' : undefined, rel: isExternal ? 'noopener noreferrer' : undefined, ...props, children: [leftIcon &&
                cloneElement(leftIcon, {
                    className: iconClassName,
                }), children, rightIcon &&
                cloneElement(rightIcon, {
                    className: iconClassName,
                })] }));
};
