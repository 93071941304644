'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Dialog from '@radix-ui/react-dialog';
import { ArrowRightLine, CrossLine } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { Button } from '../Button';
import { EmbedURL } from '../EmbedURL';
import { styles } from './Popup.styles';
export const Popup = ({ className, children, title, description, img, video, cta, onClick, contentProps, hideCloseButton, ...props }) => {
    return (_jsx(Dialog.Root, { modal: false, ...props, children: _jsx(Dialog.Portal, { children: _jsxs(Dialog.Content, { className: cn(styles.content, className), ...contentProps, children: [!hideCloseButton && (_jsx(Dialog.Close, { asChild: true, children: _jsx(Button, { variant: img ? 'muted' : 'ghost', size: "sm", className: "absolute top-100 right-100 backdrop-blur", onlyIcon: true, leftIcon: _jsx(CrossLine, {}), mutedText: true }) })), img && _jsx("img", { src: img, alt: title, className: styles.img }), video && _jsx(EmbedURL, { url: video }), title && (_jsxs("div", { className: styles.text, children: [_jsx(Dialog.Title, { className: styles.title, children: title }), description && (_jsx(Dialog.Description, { className: styles.description, children: description }))] })), cta && (_jsx(Button, { variant: "muted", size: "lg", rightIcon: _jsx(ArrowRightLine, {}), onClick: onClick, children: cta })), children] }) }) }));
};
