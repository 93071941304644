'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useTranslations } from 'next-intl';
import { CheckCircleFilled } from '@zealy/icons';
import { cn } from '../../../src/utils/cn';
import { Button } from '../Button';
import { TextArea } from '../TextArea';
import { FEEDBACK_OPTIONS } from './Feedback.constants';
export const Feedback = ({ className, title, onSubmit, ...props }) => {
    const t = useTranslations('ds.feedback');
    const [reaction, setReaction] = React.useState(null);
    const [submitted, setSubmitted] = React.useState(false);
    const handleSelect = (selectedReaction) => () => {
        setReaction(selectedReaction);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const comment = new FormData(e.currentTarget).get('comment')?.toString() ?? '';
        if (!reaction)
            return;
        onSubmit({
            reaction: reaction,
            comment,
        });
        setSubmitted(true);
    };
    return (_jsxs("form", { onSubmit: handleSubmit, className: cn('not-prose max-w-[330px] min-w-0 flex-1 flex flex-col gap-200 px-200 py-150 rounded-component-lg border border-component-tertiary', className), ...props, children: [_jsxs("div", { className: "flex items-center min-w-0 flex-col gap-100 justify-between", children: [_jsx("p", { className: "body-component-lg-bold", children: title ?? t('title') }), _jsx("div", { className: "flex gap-50 items-center min-w-0", children: FEEDBACK_OPTIONS.map(({ value, icon }) => (_jsx(Button, { "aria-label": value, type: "button", "data-state": reaction === value ? 'selected' : undefined, mutedText: reaction !== value, variant: reaction === value ? 'muted' : 'ghost', color: reaction === value ? 'cta' : 'default', onClick: handleSelect(value), leftIcon: icon, onlyIcon: true }, value))) })] }), reaction &&
                (!submitted ? (_jsxs(_Fragment, { children: [_jsx(TextArea, { name: "comment", placeholder: t('comment.placeholder') }), _jsx(Button, { size: "sm", className: "self-end mb-200", children: t('submit') })] })) : (_jsxs("div", { className: "flex flex-col items-center gap-150 py-400 text-center text-secondary body-component-lg", children: [_jsx(CheckCircleFilled, { className: "icon-brand-primary w-icon-xxl h-icon-xxl" }), _jsx("p", { children: t('submitted') }), _jsx("p", { children: t('thanks') })] })))] }));
};
