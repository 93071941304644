import { cn } from '../../utils/cn';
const content = cn([
    'flex flex-col gap-250 rounded-t-md md:rounded-b-md shadow-md bg-popover z-20',
    'fixed left-0 right-0 bottom-0 md:left-auto md:right-100 md:bottom-100',
    'p-250 md:max-w-[390px]',
    'focus:outline-none',
    'data-[state=open]:animate-slideUpAndFade',
]);
const img = 'h-[178px] block rounded-sm object-cover';
const title = 'text-primary body-paragraph-lg-bold';
const text = 'flex flex-col gap-100 pr-200';
const description = 'text-secondary body-paragraph-md';
export const styles = {
    content,
    img,
    text,
    title,
    description,
};
