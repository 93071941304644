'use client';

import type { SubmitHandler } from 'react-hook-form';
import * as Dialog from '@radix-ui/react-dialog';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslations } from 'next-intl';
import { z } from 'zod';

import type { User } from '@zealy/queries';
import { Button, OtpField, OtpSlot, TextField } from '@zealy/design-system';
import { updateEmail, useAuthenticatedUser } from '@zealy/queries';

import { useAuthError } from '#context/AuthError';
import { useReCaptcha } from '#hooks/useReCaptcha';
import { sendOtp } from '#requests/auth';
import { revalidateTags } from '#utils/serverAction';

export interface LinkEmailTriggerProps extends Dialog.DialogTriggerProps {
  onSuccess?: () => void;
  forceOpen?: boolean;
  verifyCallback?: (data: { email: string; otp: string }) => Promise<void>;
}

const UpdateUserSchema = z.object({
  email: z.string().email(),
  code: z.string().length(6).optional(),
});

const OtpLength = 6;

type UpdateUserSchemaType = z.infer<typeof UpdateUserSchema>;

/**
 * Wrapper for a button that triggers the email linking flow
 * @param onSuccess Callback to be called when the linking flow is successful
 * @param children The button content
 */
export const LinkEmailTrigger = ({
  onSuccess,
  verifyCallback,
  forceOpen,
  ...props
}: LinkEmailTriggerProps) => {
  const t = useTranslations('settings.linked-accounts.email');
  const { setAuthError } = useAuthError();
  const { refetch, data: user } = useAuthenticatedUser<User>();
  const [confirmedEmail, setConfirmedEmail] = useState('');
  const [open, setOpen] = useState(false);

  const { reCaptchaToken, reloadReCaptcha } = useReCaptcha('email_connection');

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm<UpdateUserSchemaType>({
    resolver: zodResolver(UpdateUserSchema),
    defaultValues: { email: '' },
  });

  const onSubmit: SubmitHandler<UpdateUserSchemaType> = async data => {
    if (!reCaptchaToken) {
      return setError('email', { message: 'Recaptcha token not found' });
    }
    try {
      sendOtp(data.email, reCaptchaToken, t)
        .then(() => {
          setConfirmedEmail(data.email);
        })
        .catch(e => {
          setError('email', {
            message: e.message,
          });
        });
    } finally {
      await reloadReCaptcha();
    }
  };

  const verify = async (code: string) => {
    try {
      if (verifyCallback) {
        await verifyCallback({
          email: confirmedEmail,
          otp: code,
        });
      } else {
        await updateEmail(user!.id, confirmedEmail, code);
      }
      refetch();
      onSuccess?.();
      setOpen(false);
      setAuthError(undefined);
      revalidateTags([`user:${user?.id}`]);
    } catch (error: any) {
      setError('code', {
        message: error.data?.message ?? error.message,
      });
    }
  };

  const onOpenChange = () => {
    setConfirmedEmail('');
    setOpen(prev => !prev);
  };

  const handleOtpChange = (nextOtpValue: string) => {
    if (nextOtpValue.length === OtpLength) {
      verify(nextOtpValue);
    }
  };

  return (
    <Dialog.Root open={open || !!forceOpen} onOpenChange={onOpenChange}>
      {props.children && <Dialog.Trigger className="text-left outline-none" {...props} />}
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0 z-50" />
        <Dialog.Content className="z-[55] bg-modal shadow-md p-300 flex flex-col gap-250 w-[450px] max-w-[95vw] rounded-component-xxxl fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <div className="flex flex-col gap-100">
            <Dialog.Title className="text-primary label-lg">{t('title')}</Dialog.Title>
            <Dialog.Description className="body-component-md">
              {t('description')}
            </Dialog.Description>
          </div>
          <TextField
            type="email"
            size="lg"
            placeholder={t('connect.placeholder')}
            isInvalid={!!errors.email}
            hint={errors.email?.message}
            isSuccess={isDirty && isValid}
            {...register('email')}
          />
          {!confirmedEmail ? (
            <Button
              variant="muted"
              color="cta"
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isDirty || !isValid}
              className="w-full"
            >
              {t('connect.button')}
            </Button>
          ) : (
            <>
              <p className="body-component-md">
                {t('connect.code-sent')}
                <span className="body-component-md-bold">{confirmedEmail}</span>.
              </p>
              <OtpField
                autoFocus
                onChange={handleOtpChange}
                className="mx-auto"
                size="lg"
                type="alphanumeric"
                id="otp"
              >
                <OtpSlot />
                <OtpSlot />
                <OtpSlot />
                <OtpSlot />
                <OtpSlot />
                <OtpSlot />
              </OtpField>
              {errors.code && (
                <p className="body-component-md text-error-secondary">{errors.code?.message}</p>
              )}
            </>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
