'use client';

import React from 'react';
import { useTranslations } from 'next-intl';

import { Button, TextField } from '@zealy/design-system';
import { ArrowSwapLine } from '@zealy/icons';
import { getLevelInfo, getTotalXpForLevel } from '@zealy/utils';

export const LevelCalculator = () => {
  const t = useTranslations('docs.level');
  const [level, setLevel] = React.useState(1);
  const [xp, setXp] = React.useState(0);

  return (
    <div className="flex gap-100 items-end">
      <TextField
        label={t('level')}
        name="level"
        value={level}
        onChange={e => {
          const newLevel = Number(e.target.value);
          setLevel(newLevel);
          setXp(getTotalXpForLevel(newLevel));
        }}
      />
      <Button
        onlyIcon
        leftIcon={<ArrowSwapLine />}
        aria-label={t('convert')}
        variant="ghost"
        mutedText
      />
      <TextField
        label={t('xp')}
        name="xp"
        value={xp}
        onChange={e => {
          const newXp = Number(e.target.value);
          setXp(newXp);
          const [, newLevel] = getLevelInfo(newXp);
          setLevel(newLevel);
        }}
      />
    </div>
  );
};
