import { cva, cx } from 'class-variance-authority';
const content = cva([
    'z-30 w-[332px] p-200',
    'outline-none',
    'rounded-popover border-popover shadow-popover',
    'flex flex-col items-start gap-popover-container',
    'will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade',
], {
    variants: {
        variant: {
            primary: ['bg-popover'],
            secondary: ['bg-popover-secondary'],
        },
    },
    defaultVariants: {
        variant: 'primary',
    },
});
const text = cx('gap-popover-text-container flex flex-col');
const title = cx('text-popover-title popover-title');
const description = cx('text-popover-description popover-description');
export const styles = {
    content,
    text,
    title,
    description,
};
