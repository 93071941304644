'use client';

import React, { useEffect } from 'react';
import { useRouter } from 'next/navigation';

import { useAuthenticatedUser } from '@zealy/queries';

import { LinkEmailTrigger } from '#app/cw/[subdomain]/settings/linked-accounts/_components/AccountSection/ConnectAccountButton/LinkEmailTrigger';
import { useDidMount } from '#hooks/useDidMount';

export const ConnectEmail = () => {
  const router = useRouter();
  const user = useAuthenticatedUser();
  const didMount = useDidMount();

  const email =
    user.data && 'accounts' in user.data
      ? user.data?.accounts?.find(account => account.accountType === 'email')
      : null;

  useEffect(() => {
    if (user.isFetched && user.data && !user.data?.name) {
      router.replace('/create-user');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!didMount || !user.data || !!email) return null;

  return <LinkEmailTrigger forceOpen />;
};
