'use client';

import React from 'react';

import { CommunitiesFlagProvider, useCommunitiesFlags, useUserFlag } from './CommunitiesFlags';

export function FeatureFlagsProvider({ children }: { children: React.ReactNode }) {
  return <CommunitiesFlagProvider>{children}</CommunitiesFlagProvider>;
}

export { useCommunitiesFlags, useUserFlag };
