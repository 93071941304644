import { cva } from 'class-variance-authority';
export const root = cva('flex', {
    variants: {
        size: {
            sm: 'gap-50',
            md: 'gap-50',
            lg: 'gap-100',
            xl: 'gap-100',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
export const input = cva([
    'outline-none relative',
    'box-border',
    'font-component',
    'leading-none',
    'border',
    'transition-colors duration-75 ease-out',
    'flex items-center justify-center text-center',
    'text-text-field-content',
    'data-[state="active"]:ring-2 ring-inset',
], {
    variants: {
        state: {
            default: 'hover:border-text-field-hover data-[state="active"]:ring-text-field-focus data-[state="active"]:border-none',
            error: 'ring-2 ring-text-field-focus-error border-none',
            success: 'ring-2 ring-text-field-focus-success border-none',
            disabled: 'pointer-events-none cursor-not-allowed border-text-field-disabled bg-text-field-disabled text-text-field-disabled placeholder:text-text-field-disabled',
        },
        size: {
            sm: 'h-text-field-sm w-text-field-sm rounded-text-field-sm text-text-field-sm',
            md: 'h-text-field-md w-text-field-md rounded-text-field-md text-text-field-md',
            lg: 'h-text-field-lg w-text-field-lg rounded-text-field-lg text-text-field-lg',
            xl: 'h-text-field-xl w-text-field-xl rounded-text-field-xl text-text-field-xl',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
export const styles = {
    root,
    input,
};
