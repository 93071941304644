'use client';

import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';

import { Button } from '@zealy/design-system';
import { CrossLine } from '@zealy/icons';

import { cn } from '#utils/utils';

export interface ModalProps extends Dialog.DialogProps {
  title?: string;
  description?: React.ReactNode;
  trigger?: React.ReactElement;
  className?: string;
  hideCloseButton?: boolean;
}

export const Modal = ({
  title,
  description,
  trigger,
  children,
  className,
  hideCloseButton,
  ...props
}: ModalProps) => {
  return (
    <Dialog.Root {...props}>
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0 z-overlay" />
        <Dialog.Content
          className={cn(
            'z-dialog data-[state=open]:animate-dialogContentShow outline-none bg-tertiary overflow-visible shadow-md flex items-stretch flex-col gap-100 rounded-md fixed top-700 md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 w-screen sm:w-auto max-w-screen max-h-[90vh] overflow-y-auto',
            className,
          )}
        >
          {(title || description) && (
            <div>
              {title && (
                <Dialog.Title className="text-primary headings-title-t1 pr-300">
                  {title}
                </Dialog.Title>
              )}
              {description && (
                <Dialog.Description className="text-secondary body-paragraph-lg whitespace-pre-line mt-100">
                  {description}
                </Dialog.Description>
              )}
            </div>
          )}
          {children}
          {!hideCloseButton && (
            <Dialog.Close asChild>
              <Button
                variant="ghost"
                className="absolute top-100 right-100"
                aria-label="Close"
                mutedText
                size="sm"
                onlyIcon
                leftIcon={<CrossLine />}
              />
            </Dialog.Close>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
