'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React from 'react';
import { ChevronRightLine } from '@zealy/icons';
import { cn } from '../../../src/utils/cn';
const Accordion = AccordionPrimitive.Root;
const AccordionItem = React.forwardRef(({ className, ...props }, ref) => (_jsx(AccordionPrimitive.Item, { ref: ref, className: cn('border-b border-b-separators last-of-type:border-transparent', className), ...props })));
AccordionItem.displayName = 'AccordionItem';
const AccordionTrigger = React.forwardRef(({ className, children, ...props }, ref) => (_jsx(AccordionPrimitive.Header, { className: "flex my-0", children: _jsxs(AccordionPrimitive.Trigger, { ref: ref, className: cn('flex flex-1 gap-200 items-center text-left py-150 body-component-md-bold transition-all [&[data-state=open]>svg]:rotate-90 outline-none', className), ...props, children: [_jsx(ChevronRightLine, { className: "h-icon-md w-icon-md shrink-0 transition-transform duration-200 icon-secondary" }), children] }) })));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;
const AccordionContent = React.forwardRef(({ className, children, ...props }, ref) => (_jsx(AccordionPrimitive.Content, { ref: ref, className: cn('overflow-hidden body-component-md transition-all data-[state=closed]:animate-slideUpAccordion data-[state=open]:animate-slideDownAccordion pb-200 text-secondary pl-400', className), ...props, children: children })));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;
export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
