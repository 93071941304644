import React, { useRef } from 'react';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';

import type { CommunityMember } from '@zealy/queries';
import type { SetRoleInput } from '@zealy/schemas';
import { Button } from '@zealy/design-system';
import { ChevronDownLine } from '@zealy/icons';
import { useAuthenticatedUser, useSetRole } from '@zealy/queries';

import { envConfig } from '#app/config';
import { ButtonDropdown } from '#components/ButtonDropdown';
import { toast } from '#components/Toaster';
import { useBeforeUnload } from '#hooks/useBeforeUnload';

const roles: SetRoleInput['role'][] = ['admin', 'editor', 'reviewer', 'guest', 'banned', 'suspend'];

export const ChangeRole = () => {
  const t = useTranslations('common.roles');
  const { subdomain } = useParams<{
    subdomain: string;
  }>();
  const user = useAuthenticatedUser<CommunityMember>(subdomain);

  const initialRole = useRef(user.data?.role);
  const setRole = useSetRole({
    userId: user.data?.id,
  });

  useBeforeUnload(
    setRole.status === 'success' && initialRole.current !== user.data?.role,
    "Don't forget to revert your role",
    `${envConfig.appUrl}/cw/${subdomain}`,
  );

  const handleUpdateRole = (newRole: string) => {
    if (newRole === user.data?.role) return;

    if (setRole.status !== 'success') initialRole.current = user.data?.role;

    setRole.mutate(newRole as SetRoleInput['role'], {
      onError: () => {
        toast.error('Failed to update role');
      },
    });
  };

  return (
    <ButtonDropdown
      name="role"
      namespace="common.roles"
      options={roles.map(r => ({
        value: r,
        label: r,
        variant: r === 'suspend' ? 'destructive' : undefined,
      }))}
      onValueChange={handleUpdateRole}
      className="z-50"
    >
      <Button
        variant="ghost"
        size="xs"
        rightIcon={<ChevronDownLine />}
        mutedText
        loading={setRole.isPending}
      >
        {t(user.data?.role ?? 'guest')}
      </Button>
    </ButtonDropdown>
  );
};
