import { cva } from 'class-variance-authority';
export const linkStyles = cva([
    'items-center',
    'select-none',
    'box-border',
    '::before:box-border',
    '::after:box-border',
    'inline-flex',
    'flex-shrink-0',
    'justify-center',
    'leading-none',
    'focus:outline-none',
    'font-component',
    'transition-colors',
    'duration-200',
    'ease-out',
    'focus:rounded-link-focus focus:border-b-transparent',
    'cursor-pointer',
    'focus-visible:ring-link-focus',
], {
    variants: {
        color: {
            default: [
                'text-link-neutral hover:text-link-neutral-hover active:text-link-neutral-press',
                'border-link-neutral',
            ],
            cta: [
                'text-link-brand hover:text-link-brand-hover active:text-link-brand-press',
                'border-link-brand',
            ],
        },
        underlined: {
            true: 'border-b-[1px] hover:border-transparent focus:border-transparent pb-50 font-medium',
        },
        onlyIcon: {
            true: '',
        },
        size: {
            sm: 'gap-link-sm link-classic-sm',
            md: 'gap-link-md link-classic-md',
            lg: 'gap-link-lg link-classic-lg',
        },
    },
    compoundVariants: [
        /**
         * Underlined
         */
        {
            underlined: true,
            color: 'default',
            className: 'text-link-neutral-hover border-link-neutral',
        },
        {
            underlined: true,
            color: 'cta',
            className: 'text-link-brand-hover border-link-brand',
        },
        {
            underlined: true,
            size: 'sm',
            className: 'link-underlined-sm',
        },
        {
            underlined: true,
            size: 'md',
            className: 'link-underlined-md',
        },
        {
            underlined: true,
            size: 'lg',
            className: 'link-underlined-lg',
        },
        /**
         * Only Icon
         */
        {
            onlyIcon: true,
            size: 'sm',
            className: 'w-button-sm h-button-sm',
        },
        {
            onlyIcon: true,
            size: 'md',
            className: 'w-button-md h-button-md',
        },
        {
            onlyIcon: true,
            size: 'lg',
            className: 'w-button-lg h-button-lg',
        },
    ],
    defaultVariants: {
        color: 'default',
        size: 'md',
    },
});
export const iconStyles = cva('', {
    variants: {
        size: {
            sm: 'w-link-icon-sm h-link-icon-sm',
            md: 'w-link-icon-md h-link-icon-md',
            lg: 'w-link-icon-lg h-link-icon-lg',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
