'use client';

import type { StaticImageData } from 'next/image';
import type { MouseEventHandler } from 'react';
import { useEffect, useState } from 'react';
import { cx } from 'class-variance-authority';
import { useFormatter, useNow } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { Badge, LinkBox, LinkOverlay, cardStyles as styles, Tag } from '@zealy/design-system';
import { DiscordBrand, LightningFilled, Link2Line, TwitterFilled, UserFilled } from '@zealy/icons';
import { getCommunityPath } from '@zealy/utils';

import { useEventTracker } from '#context';

import type { CommunityCardProps } from './CommunityCard.types';
import placeholder from './placeholder.webp';

export const CommunityImage = ({
  alt,
  src = placeholder.src,
  className,
}: {
  src?: string | StaticImageData;
  alt: string;
  className?: string;
}) => {
  const [imgSrc, setImgSrc] = useState(src || placeholder.src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <Image
      alt={alt || ''}
      width={40}
      height={40}
      className={cx(
        'min-w-500 min-h-500 w-500 h-500 flex-shrink-0 rounded-component-sm object-cover',
        className,
      )}
      src={imgSrc}
      onError={() => setImgSrc(placeholder.src)}
    />
  );
};

export const CommunityCard = ({
  className,
  community,
  color,
  showRank,
  trackClick,
  collection,
}: CommunityCardProps) => {
  const router = useRouter();
  const launchDate = community.launchDate ? new Date(community.launchDate) : null;

  const eventTracker = useEventTracker();

  const now = useNow({
    // Update every 10 seconds if the launch date is in the future
    updateInterval:
      launchDate && launchDate.getTime() > new Date().getTime() ? 1000 * 10 : undefined,
  });
  const format = useFormatter();

  const handleClick: MouseEventHandler<HTMLAnchorElement> = e => {
    eventTracker.analytics.track(
      'Community Card Clicked',
      {
        subdomain: community.subdomain,
        communityId: community.id,
        collection,
      },
      () => {
        if (e.ctrlKey || e.metaKey) return window.open(getCommunityPath(community), '_blank');
        router.push(getCommunityPath(community));
      },
    );
  };

  return (
    <LinkBox
      className={cx(
        'p-300 flex flex-col gap-250 transition-transform hover:scale-[1.01] duration-200 ease-out',
        styles({ variant: 'default', isClickable: true }),
        className,
      )}
      color={color}
    >
      <div className="flex gap-100 items-center">
        <span className="relative flex-shrink-0">
          <CommunityImage src={community.image ?? placeholder} alt={community.name} />
          {showRank && community.rank && (
            <Badge className="absolute -bottom-100 -right-100 px-25 backdrop-blur-sm" size="xs">
              {community.rank}
            </Badge>
          )}
        </span>
        <LinkOverlay
          as={Link}
          href={getCommunityPath(community)}
          onClick={trackClick ? handleClick : undefined}
        >
          <h4 className="heading-3 select-none line-clamp-1 min-w-0 truncate">{community.name}</h4>
        </LinkOverlay>
        {launchDate && launchDate.getTime() > now.getTime() && (
          <Badge variant="neutral" size="xs">
            {format.relativeTime(launchDate, now)}
          </Badge>
        )}
      </div>
      <p className="body-lg text-secondary select-none line-clamp-3 h-[72px]">
        {community.description}
      </p>
      <div className="flex gap-100">
        {!!community.website && (
          <Tag
            aria-label={community.website}
            variant="filled"
            size="sm"
            as={Link}
            prefetch={false}
            href={community.website}
            onClick={e => e.stopPropagation()}
            leftIcon={<Link2Line />}
            target="_blank"
            rel="noopener noreferrer"
          />
        )}
        <Tag aria-label="Quests" variant="filled" size="sm" leftIcon={<LightningFilled />}>
          {community.quests}
        </Tag>
        {!!community.totalMembers && (
          <Tag aria-label="Members" variant="filled" size="sm" leftIcon={<UserFilled />}>
            {format.number(Number(community.totalMembers), {
              notation: 'compact',
            })}
          </Tag>
        )}
        {community.twitter && (
          <Tag
            aria-label={community.twitter}
            variant="filled"
            size="sm"
            leftIcon={<TwitterFilled />}
            as={Link}
            href={`https://twitter.com/${community.twitter}`}
            onClick={e => e.stopPropagation()}
            target="_blank"
            rel="noopener noreferrer"
          >
            {!!community.twitterFollowers &&
              format.number(Number(community.twitterFollowers), {
                notation: 'compact',
              })}
          </Tag>
        )}
        {community.discord && (
          <Tag
            aria-label={community.discord}
            variant="filled"
            size="sm"
            leftIcon={<DiscordBrand />}
            as={Link}
            href={community.discord}
            onClick={e => e.stopPropagation()}
            target="_blank"
          >
            {!!community.totalDiscordMembers &&
              format.number(Number(community.totalDiscordMembers), {
                notation: 'compact',
              })}
          </Tag>
        )}
      </div>
    </LinkBox>
  );
};
