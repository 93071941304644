'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as AlertDialogPrimitive from '@radix-ui/react-dialog';
import * as React from 'react';
import { useTranslations } from 'next-intl';
import { CrossLine } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { Button } from '../Button';
import { TextField } from '../TextField';
const AlertDialogRoot = AlertDialogPrimitive.Root;
const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
const AlertDialogPortal = AlertDialogPrimitive.Portal;
const AlertDialogOverlay = React.forwardRef(({ className, children, ...props }, ref) => (_jsx(AlertDialogPrimitive.Overlay, { className: cn('bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0 z-30', className), ...props, ref: ref })));
AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;
const Content = React.forwardRef(({ className, ...props }, ref) => (_jsxs(AlertDialogPortal, { children: [_jsx(AlertDialogOverlay, {}), _jsx(AlertDialogPrimitive.Content, { ref: ref, className: cn('fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-300 border bg-dialog p-dialog shadow-dialog duration-200 sm:rounded-dialog md:w-full', 'data-[state=open]:animate-dialogContentShow data-[state=closed]:animate-dialogContentHide outline-none', className), ...props })] })));
Content.displayName = AlertDialogPrimitive.Content.displayName;
const AlertDialogHeader = ({ className, ...props }) => (_jsx("div", { className: cn('flex flex-col space-y-100 text-center sm:text-left', className), ...props }));
AlertDialogHeader.displayName = 'AlertDialogHeader';
const AlertDialogFooter = ({ className, ...props }) => (_jsx("div", { className: cn('flex flex-col-reverse sm:flex-row sm:justify-end gap-100', className), ...props }));
AlertDialogFooter.displayName = 'AlertDialogFooter';
const AlertDialogTitle = React.forwardRef(({ className, ...props }, ref) => (_jsx(AlertDialogPrimitive.Title, { ref: ref, className: cn('dialog-title text-dialog-title', className), ...props })));
AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName;
const AlertDialogDescription = React.forwardRef(({ className, ...props }, ref) => (_jsx(AlertDialogPrimitive.Description, { ref: ref, className: cn('dialog-description text-dialog-description', className), ...props })));
AlertDialogDescription.displayName = AlertDialogPrimitive.Description.displayName;
const AlertDialogCancel = AlertDialogPrimitive.Close;
const AlertDialogAnatomy = {
    Root: AlertDialogRoot,
    Trigger: AlertDialogTrigger,
    Portal: AlertDialogPortal,
    Overlay: AlertDialogOverlay,
    Content,
    Header: AlertDialogHeader,
    Footer: AlertDialogFooter,
    Title: AlertDialogTitle,
    Description: AlertDialogDescription,
    Cancel: AlertDialogCancel,
};
const AlertDialogContent = ({ title, description, cancelLabel, confirmLabel, confirmClassName, confirmationMessage, textFieldProps, onConfirm, onCancel, ...props }) => {
    const t = useTranslations('common');
    const [confirm, setConfirm] = React.useState('');
    return (_jsxs(Content, { ...props, children: [_jsxs(AlertDialogHeader, { children: [_jsx(AlertDialogTitle, { children: title ?? t('confirm-title') }), description && _jsx(AlertDialogDescription, { children: description }), _jsx(AlertDialogCancel, { asChild: true, children: _jsx(Button, { variant: "ghost", onlyIcon: true, leftIcon: _jsx(CrossLine, {}), className: "absolute top-0 right-100", size: "sm" }) })] }), confirmationMessage && (_jsx(TextField, { name: "confirm", label: "Confirm", placeholder: confirmationMessage, value: confirm, onChange: e => setConfirm(e.target.value), ...textFieldProps })), _jsxs(AlertDialogFooter, { children: [_jsx(AlertDialogCancel, { asChild: true, children: _jsx(Button, { variant: "muted", onClick: onCancel, children: cancelLabel ?? t('cancel') }) }), _jsx(Button, { color: "destructive", className: confirmClassName, isDisabled: !!confirmationMessage && confirm !== confirmationMessage, onClick: onConfirm, children: confirmLabel ?? t('continue') })] })] }));
};
export { AlertDialogAnatomy, AlertDialogContent, AlertDialogRoot, AlertDialogTrigger };
