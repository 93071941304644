import type { FormEventHandler } from 'react';
import { useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  IconButton,
  TextField,
} from '@zealy/design-system';
import { UserArrowBlLine } from '@zealy/icons';
import { useImpersonateUserMutation } from '@zealy/queries';

import { toast } from '#components/Toaster';

export const Impersonate = () => {
  const impersonateMutation = useImpersonateUserMutation();
  const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const email = formData.get('email')?.toString() || '';

    impersonateMutation.mutate(email, { onError: error => toast.error(error.message) });
  };

  useEffect(() => {
    if (!impersonateMutation.isSuccess) return;

    toast.success('You will be redirected to the provided email in 2 seconds');
    const timerID = setTimeout(() => {
      window.location.reload();
    }, 2000);

    return () => {
      clearTimeout(timerID);
    };
  }, [impersonateMutation.isSuccess]);

  useEffect(() => {
    if (!impersonateMutation.isError) return;

    toast.error(impersonateMutation.error.message);
  }, [impersonateMutation.isError, impersonateMutation.error]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <IconButton
          mutedText
          tooltip="Impersonate"
          icon={<UserArrowBlLine />}
          variant="ghost"
          size="xs"
        />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Impersonate a user</DialogTitle>
          <DialogDescription>
            As a member of the Zealy crew, you will have access to a given account for debugging
            purpose. Only company members have access to this feature. With great power comes great
            responsibility.
          </DialogDescription>
        </DialogHeader>

        <form className="flex flex-1 flex-col gap-100" onSubmit={handleSubmit}>
          <TextField
            name="email"
            type="email"
            placeholder="e.g: john.doe@zealy.io"
            label="Email address"
            // isInvalid={!!error}
            // hint={error}
            autoComplete="email"
          />

          <Button color="cta" type="submit" loading={impersonateMutation.isPending}>
            Impersonate
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
