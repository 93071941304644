import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { Select, SelectItem } from '../Select';
export function createHoursAndMinutesInADay() {
    const hoursAndMinutes = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            hoursAndMinutes.push(`${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`);
        }
    }
    return hoursAndMinutes;
}
export const formattedTimes = createHoursAndMinutesInADay().map(time => {
    const dateObject = parse(time, 'HH:mm', new Date());
    return {
        label: format(parse(time, 'HH:mm', new Date()), 'hh:mm a'),
        value: dateObject.getHours() * 60 + dateObject.getMinutes(),
    };
});
/**
 * Time picker component used to select time
 */
export function TimePicker({ value, onValueChange, hideTimeZone = false, ...props }) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return (_jsxs("div", { className: "flex items-center gap-200 z-0", children: [_jsx(Select, { value: String((value?.hours ?? 12) * 60 + (value?.minutes ?? 0)), onValueChange: timeString => {
                    const time = parseInt(timeString);
                    const hours = Math.trunc(time / 60) ?? 0;
                    const minutes = Math.trunc(time % 60) ?? 0;
                    onValueChange?.(hours, minutes);
                }, ...props, contentClassName: "z-[70]", children: formattedTimes.map(({ label, value }) => (_jsx(SelectItem, { value: value.toString(), children: label }, value))) }), !hideTimeZone && _jsx("p", { className: "text-secondary body-component-md", children: timeZone })] }));
}
