import { useFps } from '#features/DevToolbar/hooks/useFps';

import { FpsChart } from './FpsChart';

const GcTimeInSecond = 30; // Only show the last 30 seconds

export const FpsSection = () => {
  const { fps, fpsArray } = useFps(GcTimeInSecond);
  const count = fpsArray.reduce((acc, curr) => acc + curr, 0);
  const avg = count ? Math.round(count / fpsArray.length) : 60;

  return (
    <div className="text-secondary border-l-separators pl-200 gap-200 flex flex-row items-center">
      <div className="text-xs flex flex-row gap-100 items-center">
        FPS: <strong className="font-mono">{fps}</strong>
      </div>
      <div className="text-xs flex flex-row gap-100 items-center">
        Avg: <strong className="font-mono">{avg}</strong>
      </div>
      <FpsChart fps={fpsArray} gcTimeInS={GcTimeInSecond} />
    </div>
  );
};
