import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { Tag } from '@zealy/design-system';
import { DiscordBrand, Link2Line, MapViewFilled, TwitterFilled, UserFilled } from '@zealy/icons';
import { Community } from '@zealy/queries';

import { CommunityImage } from '#components/CommunityCard';
import { getTwitterAccountURL } from '#utils/urls';

const extractHost = (url: string) => {
  try {
    const urlObject = new URL(url);

    return urlObject.hostname.replace('www.', '');
  } catch (e) {
    return url;
  }
};

export const CommunityProfile = ({
  community,
  className,
}: {
  community: Community;
  className?: string;
}) => {
  const t = useTranslations('onboarding.steps.launch');
  const intl = new Intl.NumberFormat('en-US', { notation: 'compact' });

  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center gap-400 max-w-[590px] relative w-full',
        className,
      )}
    >
      <div className="flex flex-col items-center gap-150">
        {community.image && <CommunityImage alt={community.name} src={community.image} />}
        <span className="headings-headline-h3">{community.name}</span>
      </div>
      {community.description && (
        <span className="body-paragraph-lg text-secondary text-center">
          {community.description}
        </span>
      )}
      <div className="flex flex-wrap flex-row gap-100">
        {community.website && (
          <Tag
            size="sm"
            leftIcon={<Link2Line />}
            target="_blank"
            rel="noopener noreferrer"
            as="a"
            href={community.website}
          >
            {extractHost(community.website)}
          </Tag>
        )}
        {community.discord && (
          <Tag
            leftIcon={<DiscordBrand />}
            target="_blank"
            rel="noopener noreferrer"
            as="a"
            href={community.discord}
            size="sm"
          >
            {intl.format(Number(community.totalDiscordMembers))}
          </Tag>
        )}
        {community.twitter && (
          <Tag
            leftIcon={<TwitterFilled />}
            target="_blank"
            rel="noopener noreferrer"
            as="a"
            href={getTwitterAccountURL(community.twitter)}
            size="sm"
          >
            {intl.format(Number(community.twitterFollowers))}
          </Tag>
        )}
        {community.totalMembers && (
          <Tag leftIcon={<UserFilled />} size="sm">
            {intl.format(Number(community.totalMembers))}
          </Tag>
        )}
        {community.quests && (
          <Tag leftIcon={<MapViewFilled />} size="sm">
            {intl.format(Number(community.quests))}
          </Tag>
        )}
      </div>
    </div>
  );
};
