import { Children, isValidElement } from 'react';
/**
 * Gets only the valid children of a component,
 * and ignores any nullish or falsy child.
 *
 *
 * @param children the children
 */
export function getValidChildren(children) {
    return Children.toArray(children).filter(child => isValidElement(child));
}
