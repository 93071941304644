'use client';

import type { ReactElement } from 'react';
import React from 'react';
import { useTranslations } from 'next-intl';

import {
  DropdownContent,
  DropdownItem,
  DropdownMenu,
  DropdownPortal,
  DropdownTrigger,
} from '@zealy/design-system';

import type { Messages } from '#types';

export interface Option {
  value: string;
  icon?: ReactElement;
  label: Messages['quest'] | Messages['questboard'] | Messages['common']['roles'];
  variant?: 'destructive';
  disabled?: boolean;
}

export interface ButtonDropdownProps {
  name: string;
  options: Option[];
  children: React.ReactNode;
  namespace?: 'questboard' | 'quest' | 'common.roles';
  // eslint-disable-next-line no-unused-vars
  onValueChange?: (value: string) => void;
  disabled?: boolean;
  className?: string;
}

export const ButtonDropdown = ({
  name,
  options,
  children,
  namespace = 'questboard',
  onValueChange,
  disabled,
  className,
}: ButtonDropdownProps) => {
  const t = useTranslations(namespace);

  return (
    <DropdownMenu name={name} size="sm">
      <DropdownTrigger className="outline-none" asChild disabled={disabled}>
        {children}
      </DropdownTrigger>
      <DropdownPortal>
        <DropdownContent align="end" className={className}>
          {options.map(({ value, icon, label, variant, ...props }) => (
            <DropdownItem
              key={value}
              onClick={e => {
                onValueChange?.(value);
                e.stopPropagation();
              }}
              leftIcon={icon}
              type={variant}
              {...props}
            >
              {t(label)}
            </DropdownItem>
          ))}
        </DropdownContent>
      </DropdownPortal>
    </DropdownMenu>
  );
};
