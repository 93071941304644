'use client';

import { useLocalStorageValue } from '@react-hookz/web';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';

import type { Community, CommunityMember } from '@zealy/queries';
import type { UserRole } from '@zealy/utils';
import { Card, IconButton, Link, TooltipProvider } from '@zealy/design-system';
import { CrossLine, ExternalLink2Line } from '@zealy/icons';
import { useAuthenticatedUser } from '@zealy/queries';
import { roleIsAtLeast } from '@zealy/utils';

import { allChangelogs } from '#.contentlayer/generated';
import { Post } from '#app/(landing)/(withNav)/changelog/post';
import { Modal } from '#components/Modal';

const lastChangelog = allChangelogs
  .filter(post => post.published)
  .sort((a, b) => (dayjs(a.date).isBefore(b.date) ? 1 : -1))
  .shift();

const isLastChangelogAlreadySeen = (changelogLastSeen: string | undefined) => {
  if (!lastChangelog || !changelogLastSeen) {
    return false;
  }

  return dayjs(changelogLastSeen) >= dayjs(lastChangelog.date);
};

const isLastChangelogOlderThanAWeek = () =>
  lastChangelog && dayjs(lastChangelog.date).isBefore(dayjs().startOf('date').subtract(1, 'week'));

const isLastChangelogRelevantToUserRole = (
  userRole: UserRole | null | undefined,
  minTargetRole: UserRole | undefined,
) => (minTargetRole ? roleIsAtLeast(userRole, minTargetRole) : true);

export const ChangelogBanner = ({ community }: { community: Community }) => {
  const t = useTranslations('changelog');
  const tCommon = useTranslations('common');

  const user = useAuthenticatedUser<CommunityMember>(community.subdomain);

  const { value: changelogBannerLastSeen, set: setChangelogBannerLastSeen } =
    useLocalStorageValue<string>('changelog:banner-last-seen');

  const initiallyDisplayBanner =
    lastChangelog &&
    !isLastChangelogAlreadySeen(changelogBannerLastSeen) &&
    !isLastChangelogOlderThanAWeek() &&
    isLastChangelogRelevantToUserRole(user.data?.role, lastChangelog.minTargetRole);

  const [displayBanner, setDisplayBanner] = useState(initiallyDisplayBanner);
  const [openModal, setOpenModal] = useState(false);

  const onClickChangelogBanner = () => {
    setOpenModal(true);
    setDisplayBanner(false);
  };

  useEffect(() => {
    if (initiallyDisplayBanner) {
      setDisplayBanner(true);
      setChangelogBannerLastSeen(lastChangelog.date);
    }
  }, [setChangelogBannerLastSeen, setDisplayBanner, initiallyDisplayBanner]);

  return (
    <>
      {lastChangelog && (
        <>
          {displayBanner && (
            <Card
              className="absolute bottom-200 left-200 z-10 px-200 py-150 flex flex-col text-xs bg-tertiary w-[280px]"
              onClick={onClickChangelogBanner}
            >
              <div className="flex justify-between items-center">
                <span className="text-tertiary">{t('title')}</span>
                <TooltipProvider>
                  <IconButton
                    icon={<CrossLine />}
                    variant="ghost"
                    size="xs"
                    onClick={e => {
                      e.stopPropagation();
                      setDisplayBanner(false);
                    }}
                    onlyIcon
                    tooltip={tCommon('close')}
                  />
                </TooltipProvider>
              </div>
              <span>{lastChangelog.title}</span>
            </Card>
          )}
          <Modal open={openModal} onOpenChange={setOpenModal} className="w-full px-400">
            <div className="mt-200 text-center">
              <Link rightIcon={<ExternalLink2Line />} href="/changelog" target="_blank">
                {t('banner-link')}
              </Link>
            </div>

            <Post post={lastChangelog} hideLayout />
          </Modal>
        </>
      )}
    </>
  );
};
