import type { useTranslations } from 'next-intl';

import { otpLogin, sendOtp as sendOtpQuery } from '@zealy/queries';
import { AuthError } from '@zealy/utils';

import { envConfig } from '#app/config';

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const sendOtp = async (
  email: string,
  reCaptchaToken: string,
  t: ReturnType<typeof useTranslations>,
  invitationId?: string,
) => {
  if (!EMAIL_REGEX.test(email)) {
    throw new Error('Invalid email');
  }

  try {
    await sendOtpQuery({ email, reCaptchaToken, invitationId });
  } catch (error: any) {
    let message = error.message;
    if (!message && error.code === 429) {
      message = t('rate-limit-error');
    }
    throw new Error(message || t('invalid-otp-error'));
  }

  return true;
};

export const verifyOtp = async (email: string, otp: string) => {
  if (!EMAIL_REGEX.test(email)) {
    throw new AuthError({ message: 'Invalid email' });
  }

  try {
    await otpLogin({ email, otp });
  } catch (error: any) {
    throw new Error(error.message);
  }

  return true;
};

export const validateName = async (name: string) => {
  const response = await fetch(`${envConfig.apiUrl}/users/checkname/${name}`);

  const { taken } = await response.json();
  return taken;
};
