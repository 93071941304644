import * as Accordion from '@radix-ui/react-accordion';
import React, { forwardRef } from 'react';
import { cva } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useParams } from 'next/navigation';

import { Button } from '@zealy/design-system';
import { CheckCircle2Line, ChevronDownLine, PlayCircleFilled, RecordLine } from '@zealy/icons';

import { cn } from '#utils/utils';

import { Step } from './Onboarding.types';

const stepStyles = cva('flex px-100 gap-100 py-100 items-center', {
  variants: {
    completed: {
      true: 'text-tertiary',
      false: 'text-primary',
    },
  },
});

interface HeaderProps {
  children?: React.ReactNode;
  completed?: boolean;
  active?: boolean;
  title: string;
  className?: string;
}

const StepHeader = forwardRef(function stepHeader(
  { title, completed, active, children, className, ...props }: HeaderProps,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <div
      className={cn(
        stepStyles({
          completed,
        }),
        className,
      )}
      ref={ref}
      {...props}
    >
      {completed ? (
        <CheckCircle2Line size={16} data-testid="check-icon" />
      ) : (
        <RecordLine
          className={active ? 'icon-brand-secondary' : ''}
          size={16}
          data-testid="circle-icon"
        />
      )}
      <p className="body-component-sm-bold flex-1 text-left">{title}</p>
      {children}
    </div>
  );
});

export const OnboardingStep = ({ name, active, completed, link, videoLink, icon }: Step) => {
  const { subdomain } = useParams();
  const t = useTranslations('onboarding');

  const title = t(`steps.${name}.label`);

  if (!link && name !== 'launch')
    return (
      <StepHeader
        {...{
          title,
          completed,
          active,
        }}
      />
    );

  const description = t(`steps.${name}.description`);
  const cta = t(`steps.${name}.cta`);

  return (
    <Accordion.Item value={name} className="group/onboarding-item">
      <Accordion.Header className="rounded-component-sm hover:bg-component-quaternary-hover active:bg-component-quaternary-press transition-colors">
        <Accordion.Trigger asChild className="cursor-pointer">
          <StepHeader {...{ title, completed, active }}>
            <ChevronDownLine
              size={12}
              className="group-data-[state=open]/onboarding-item:rotate-180"
              data-testid="chevron-down"
            />
          </StepHeader>
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content className="flex flex-col gap-100 items-start pl-400 pr-100 data-[state=open]:py-50 data-[state=closed]:h-0 data-[state=closed]:animate-slideUpAccordion data-[state=open]:animate-slideDownAccordion overflow-hidden">
        <p className="body-paragraph-sm text-secondary">{description}</p>

        <Button
          size="xs"
          variant="muted"
          href={`/cw/${subdomain}${link}`}
          leftIcon={icon}
          as={Link}
        >
          {cta}
        </Button>

        <Button
          size="xs"
          variant="ghost"
          href={videoLink}
          leftIcon={<PlayCircleFilled />}
          mutedText
          as={Link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('video')}
        </Button>
      </Accordion.Content>
    </Accordion.Item>
  );
};
