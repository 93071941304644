'use client';

import { PopoverTrigger } from '@radix-ui/react-popover';
import React, { useState } from 'react';
import Link from 'next/link';
import { useParams } from 'next/navigation';

import { Popover, PopoverContent } from '@zealy/design-system';

import { useIsMobile } from '#hooks/useIsMobile';

import { InboxContent } from './InboxContent';

export const InboxPopover = ({
  children,
  disabled = false,
}: {
  children: React.ReactNode;
  disabled?: boolean;
}) => {
  const { subdomain } = useParams();
  const [open, setOpen] = useState(false);

  const isMobile = useIsMobile();

  if (isMobile)
    return (
      <Link
        href={`/cw/${subdomain}/inbox`}
        aria-disabled={disabled}
        prefetch={false}
        className="w-full"
      >
        {children}
      </Link>
    );

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent
        side="right"
        align="end"
        sideOffset={16}
        className="!p-0 rounded-inbox-popover h-[calc(100dvh-16px)] mt-100 w-[390px] max-h-[1048px]"
      >
        {open && <InboxContent />}
      </PopoverContent>
    </Popover>
  );
};
