'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import { mergeRefs } from '../../hooks/useMergeRefs';
import { cn } from '../../utils/cn';
import { FeedbackIcon, getComponentState } from '../../utils/getComponentState';
import { FormControl } from '../FormControl';
import { styles as inputStyles } from '../TextField/TextField.styles';
import { styles } from './TextArea.styles';
export const TextArea = forwardRef(function TextArea({ label, name, isInvalid, isDisabled, isSuccess, size, hint, className, optional, rows, disableResize, ...props }, ref) {
    const textareaRef = React.useRef(null);
    const mergedRefs = mergeRefs(ref, textareaRef);
    const state = getComponentState({ isDisabled, isInvalid, isSuccess });
    const Icon = FeedbackIcon[state];
    const adjustHeight = (e) => {
        if (!disableResize && textareaRef?.current) {
            textareaRef.current.style.height = 'inherit'; // Reset height to recalculate
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
        props.onChange?.(e);
    };
    return (_jsx(FormControl, { size, label, name, hint, state, optional, children: _jsxs("div", { className: cn(styles.root({
                size,
                state,
            }), className), children: [_jsx("textarea", { id: name, name: name, disabled: isDisabled, ref: mergedRefs, rows: rows, ...props, onChange: adjustHeight, className: cn(styles.input({
                        size,
                        state,
                    })) }), Icon && (_jsx(Icon, { "aria-label": state, className: cn(inputStyles.icon({
                        size,
                        state,
                    }), 'absolute right-150 bottom-150') }))] }) }));
});
