'use client';
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { CheckCircleFilled } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { ProgressBarAnatomy as Anatomy } from './ProgressBar.anatomy';
import { progressBar } from './ProgressBar.styles';
const clamp = (num, max) => Math.min(Math.max(num, 0), max);
/**
 * The ProgressBar component is used to display the progress of a task or activity in which the user is involved.
 * It can be used to show the progress of a quest, a level, a skill, or any other activity like loading.
 * It assembles all the ana Anatomy components of the progress bar into a simple terse API.
 */
export const ProgressBar = ({ size = 'md', variant = 'neutral', value = 0, max, label, description, rewardImage, hideLabels = false, ...props }) => {
    const clampedValue = clamp(value, max);
    const isComplete = useMemo(() => clampedValue === max, [clampedValue, max]);
    return (_jsxs(Anatomy.Root, { size: size, variant: variant, value: clamp(value, max), max: max, ...props, children: [_jsxs(Anatomy.LabelGroup, { className: hideLabels ? 'sr-only' : '', children: [_jsx(Anatomy.Label, { children: !isComplete ? (_jsxs(_Fragment, { children: [_jsxs(Anatomy.Stats, { children: [clamp(value, max), " / ", max] }), label && _jsx("div", { children: label })] })) : (_jsxs("div", { "data-scope": "progressbar", "data-part": "complete", className: 'flex justify-center items-center gap-50', children: [_jsx(Anatomy.Stats, { children: "Complete" }), _jsx(CheckCircleFilled, { className: cn(progressBar.statsicon({ size })) })] })) }), description && (_jsx(Anatomy.Label, { "data-scope": "progressbar", "data-part": "description", children: _jsx("div", { children: description }) }))] }), _jsx(Anatomy.Track, { rewardImage: rewardImage })] }));
};
