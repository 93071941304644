import { useEffect, useRef } from 'react';
export const useIntersectionObserver = (onIntersect, options) => {
    const ref = useRef(null);
    const optionsRef = useRef(options);
    const onIntersectRef = useRef(onIntersect);
    optionsRef.current = options;
    onIntersectRef.current = onIntersect;
    useEffect(() => {
        if (!ref.current)
            return;
        const el = ref.current;
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    onIntersectRef.current();
                }
            });
        }, optionsRef.current);
        observer.observe(el);
        return () => {
            observer.disconnect();
        };
    }, []);
    return ref;
};
export default useIntersectionObserver;
