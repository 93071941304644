'use client';

import { useEffect } from 'react';
import { useCookie } from 'react-use';

import { Popup } from '@zealy/design-system';
import { useAuthenticatedUser } from '@zealy/queries';
import { roleIsAtLeast, UserRole } from '@zealy/utils';

import { useCommunitiesFlags } from '#context/FeatureFlags';

import { usePopup } from './PopupProvider';

interface FlagValue {
  id: string;
  title: string;
  description?: string;
  cta?: string;
  img?: string;
  video?: string;
  link?: string;
  role?: UserRole;
}

export const FeatureFlagPopup = () => {
  const flags = useCommunitiesFlags(['popup']);
  const user = useAuthenticatedUser();

  const { isOpen, onOpenChange } = usePopup({
    id: 'ff_popup',
    priority: 3,
  });

  const { enabled, value } = flags.popup;

  const { title, description, cta, img, link, role, id, video } = JSON.parse(
    (value as string) || '{}',
  ) as FlagValue;

  const [seenFlag, setSeenFlag] = useCookie(`popup:${id}`);

  const showPopup = !(
    !enabled ||
    !!seenFlag ||
    !value ||
    (role && user.data && 'role' in user.data && !roleIsAtLeast(user.data?.role, role))
  );

  useEffect(() => {
    if (showPopup) {
      onOpenChange(true);
    }
    return () => {
      onOpenChange(false);
    };
  }, [showPopup, onOpenChange]);

  const setSeen = () => {
    setSeenFlag('true', {
      expires: 7,
    });
    onOpenChange(false);
  };

  const onClick = () => {
    setSeen();
    if (link) window.open(link, '_blank');
  };

  return (
    <Popup
      className="dark"
      open={isOpen}
      onOpenChange={setSeen}
      contentProps={{
        onPointerDownOutside: e => {
          e.stopPropagation();
          e.preventDefault();
        },
      }}
      {...{ title, description, cta, img, link, onClick, video }}
    />
  );
};
