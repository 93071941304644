'use client';

import dayjs from 'dayjs';

import type { User } from '@zealy/queries';
import { useAuthenticatedUser, useLastNPS } from '@zealy/queries';

/**
 *
 * @param userId
 * @returns a number between 1 and 28, which is the day of the
 * month when the NPS popup should be shown based on the user id
 */
const dateToShowNps = (userId: string) => (parseInt(userId, 16) % 28) + 1;

export const shouldShowNPS = (user: Pick<User, 'createdAt' | 'id'>) =>
  // We show the NPS popup on different days of the month depending on the user id
  dayjs().date() === dateToShowNps(user.id) &&
  // Only ask users who have been around for at least 2 weeks
  dayjs(user?.createdAt).isBefore(dayjs().subtract(2, 'week'));

export const answeredThisMonth = (
  nps?: {
    month: number;
    year: number;
  } | null,
) =>
  nps &&
  // getMonth() returns 0-11, so we add 1 to get the current month
  nps.month === new Date().getMonth() + 1 &&
  nps.year === new Date().getFullYear();

export const useShouldShowNPS = () => {
  const user = useAuthenticatedUser<User>();

  const nps = useLastNPS({
    userId: 'me',
    enabled: !!user.data && shouldShowNPS(user.data),
  });

  const shouldHidePopup =
    !user.data || !shouldShowNPS(user.data) || !nps.isFetched || answeredThisMonth(nps.data);

  return !shouldHidePopup;
};
