'use client';

import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useTranslations } from 'next-intl';
import { useParams, useSearchParams } from 'next/navigation';

import type { CommunityMember } from '@zealy/queries';
import { Avatar, Button } from '@zealy/design-system';
import {
  communityKeys,
  FetchError,
  isUserConnected,
  joinCommunity,
  useAuthenticatedUser,
  useInvitation,
  usersKeys,
} from '@zealy/queries';
import { roleIsAtLeast } from '@zealy/utils';

import { queryClient } from '#app/QueryProvider';
import { useDidMount } from '#hooks/useDidMount';
import { ICommunity } from '#types';
import { revalidateTags } from '#utils/serverAction';
import { signOut } from '#utils/signout';

import { ConnectButton } from './ConnectButton';

export const JoinCommunityBar = ({ community }: { community?: ICommunity }) => {
  const didMount = useDidMount();
  const { data: user, isLoading } = useAuthenticatedUser<CommunityMember>();
  const { invitationId, questId, moduleId } = useParams<{
    invitationId: string;
    questId?: string;
    moduleId?: string;
  }>();
  const searchParams = useSearchParams();
  const inviteId = searchParams.get('invitationId') ?? invitationId;

  useEffect(() => {
    if (inviteId) {
      Cookies.set('invitationId', inviteId, { expires: 7 }); // 7 days
    }
  }, [inviteId]);

  const isPrivate = community?.visibility === 'private';

  const t = useTranslations();
  const { data: invite } = useInvitation({ id: inviteId });

  const validInvite = invite?.status === 'valid' && !roleIsAtLeast(user?.role, invite?.role);

  if (!didMount || (isUserConnected() && isLoading) || (user?.role && !validInvite)) {
    return null;
  }

  const onClickJoin = async () => {
    if (!community) return;
    try {
      await joinCommunity(community?.subdomain, validInvite ? inviteId : undefined);
      revalidateTags([`user:${user?.id}`]);
      await queryClient.invalidateQueries({ queryKey: communityKeys.userCommunities() });
      await queryClient.invalidateQueries({ queryKey: usersKeys.user('me') });
    } catch (e) {
      if (e instanceof FetchError && e.status === 403) signOut();
      console.error(e);
    }
  };

  // We show this specific banner on a given quest page when a user
  // invites other users and the admin of the quest has set a mandatory
  // quest to fulfill before being able to claim. The message is slighly different
  const isQuestboardPage = Boolean(questId && moduleId);

  return (
    <div className="bg-tertiary rounded-sm overflow-hidden py-300 px-200 flex flex-col md:flex-row items-center gap-150 shrink-0">
      {validInvite && <Avatar src={invite?.user.avatar} size="xs" name={invite.user.name} />}
      <span className="body-paragraph-md-bold text-primary flex-1">
        {validInvite &&
          !isQuestboardPage &&
          t('community.invite', {
            userName: invite.user.name,
            communityName: community?.name,
          })}

        {validInvite &&
          isQuestboardPage &&
          t('community.invite-with-quest', {
            userName: invite.user.name,
            communityName: community?.name,
          })}

        {!validInvite && t('community.join.description')}
      </span>
      {user ? (
        <Button onClick={onClickJoin} color="cta" size="sm" isDisabled={!validInvite && isPrivate}>
          {t('common.join-community', { communityName: community?.name })}
        </Button>
      ) : (
        <ConnectButton size="sm">
          {t('common.connect-to', {
            platform: t('common.brandName'),
          })}
        </ConnectButton>
      )}
    </div>
  );
};
