'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';
import { cn } from '../../utils/cn';
const Drawer = ({ shouldScaleBackground = true, ...props }) => (_jsx(DrawerPrimitive.Root, { shouldScaleBackground: shouldScaleBackground, ...props }));
Drawer.displayName = 'Drawer';
const DrawerTrigger = DrawerPrimitive.Trigger;
const DrawerPortal = DrawerPrimitive.Portal;
const DrawerClose = DrawerPrimitive.Close;
const DrawerOverlay = React.forwardRef(({ className, ...props }, ref) => (_jsx(DrawerPrimitive.Overlay, { ref: ref, className: cn('fixed inset-0 z-overlay bg-blackA3', className), ...props })));
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;
const Slider = () => (_jsx("div", { className: "mx-auto my-200 h-100 w-[100px] rounded-full bg-component-secondary" }));
const DrawerContent = React.forwardRef(({ className, children, ...props }, ref) => (_jsxs(DrawerPortal, { children: [_jsx(DrawerOverlay, {}), _jsxs(DrawerPrimitive.Content, { ref: ref, className: cn('fixed inset-x-0 bottom-0 z-drawer mt-1200 flex h-auto flex-col rounded-t-popover border bg-popover outline-none p-200 pb-400 gap-200 max-h-[90vh]', className), ...props, children: [_jsx(Slider, {}), children] })] })));
DrawerContent.displayName = 'DrawerContent';
const DrawerHeader = ({ className, ...props }) => (_jsx("div", { className: cn('grid gap-50 p-200 text-center sm:text-left', className), ...props }));
DrawerHeader.displayName = 'DrawerHeader';
const DrawerFooter = ({ className, ...props }) => (_jsx("div", { className: cn('mt-auto flex flex-col gap-100 p-200', className), ...props }));
DrawerFooter.displayName = 'DrawerFooter';
const DrawerTitle = React.forwardRef(({ className, ...props }, ref) => (_jsx(DrawerPrimitive.Title, { ref: ref, className: cn('body-component-lg-bold text-primary', className), ...props })));
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;
const DrawerDescription = React.forwardRef(({ className, ...props }, ref) => (_jsx(DrawerPrimitive.Description, { ref: ref, className: cn('body-paragraph-md text-secondary', className), ...props })));
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;
export { Drawer, DrawerPortal, DrawerOverlay, DrawerTrigger, DrawerClose, DrawerContent, DrawerHeader, DrawerFooter, DrawerTitle, DrawerDescription, };
