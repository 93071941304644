import { cx } from 'class-variance-authority';
const root = cx(['relative flex-shrink-0', 'w-[var(--community-bar-width)]', 'h-full', 'z-[1]']);
const bar = cx([
    'flex flex-col gap-200 group/bar bg-secondary',
    'absolute top-0 bottom-0 left-0 right-0 h-full',
    'p-150 pb-300',
    'rounded-md',
    'w-fit',
    'shadow data-[state=open]:animate-slideRight',
    'data-[state=closed]:animate-slideLeft',
    'data-[state=open]:w-[var(--community-bar-expanded-width)]',
    'overflow-x-hidden overflow-y-auto no-scrollbar',
    'min-w-full',
]);
const search = cx('group-data-[state=open]/bar:flex hidden flex-shrink-0');
export const styles = {
    root,
    bar,
    search,
};
