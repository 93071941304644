import type { Author, Changelog } from 'contentlayer/generated';
import React from 'react';
import { useFormatter } from 'next-intl';
import Image from 'next/image';
import NextLink from 'next/link';

import { Link } from '@zealy/design-system';
import { ArrowLeftLine } from '@zealy/icons';

import { Mdx } from '#components/mdx-components';
import { cn } from '#utils/utils';

interface PostProps {
  post: Changelog;
  hideLayout?: boolean;
  authors?: (Author | undefined)[];
}

export const Post = ({ post, hideLayout }: PostProps) => {
  const format = useFormatter();

  return (
    <div className="max-w-landing grid grid-cols-4 md:grid-cols-12 gap-200 md:gap-300 mx-auto py-800 prose prose-a:no-underline">
      {/* Article metadata */}
      <div className="col-span-4 md:col-span-3 flex flex-col gap-400 items-start">
        {!hideLayout && (
          <Link leftIcon={<ArrowLeftLine />} href="/changelog" size="lg">
            Back to all posts
          </Link>
        )}
        <p className="body-lg text-tertiary">
          {format.dateTime(new Date(post.date), {
            dateStyle: 'medium',
          })}
        </p>
      </div>
      {/* Article header */}
      <article className="col-span-4 md:col-span-6 flex flex-col mb-600">
        <Image
          width={659}
          height={360}
          src={post.image}
          alt={post.title}
          className="w-full rounded-component-md mb-600 aspect-video object-cover object-top"
        />
        <NextLink href={post.slug}>
          <h1 className="headings-headline-h1 text-primary mb-0">{post.title}</h1>
        </NextLink>
        {/* Article content */}
        <Mdx
          code={post.body.code}
          overwriteComponents={{
            ul: ({ className, ...props }) => (
              <ul
                className={cn(
                  'list-image-minus pl-250 text-secondary body-paragraph-lg',
                  className,
                )}
                {...props}
              />
            ),
          }}
        />
      </article>
    </div>
  );
};
