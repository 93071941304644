'use client';

import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useParams } from 'next/navigation';

import type { CommunityMember } from '@zealy/queries';
import { Badge, Button } from '@zealy/design-system';
import {
  ChartBarFilled,
  ExportLine,
  GamesFilled,
  InfinityLine,
  TrendUpLine,
  UsersFilled,
} from '@zealy/icons';
import { useAuthenticatedUser, useCommunity } from '@zealy/queries';

import { useEventTracker } from '#context';

import type { ModalProps } from './Modal';
import { Modal } from './Modal';

const BENEFITS = [
  'increased-limits',
  'visibility',
  'crm',
  'analytics',
  'export',
  'co-marketing',
] as const;

const BENEFIT_ICONS = {
  'increased-limits': InfinityLine,
  visibility: TrendUpLine,
  crm: UsersFilled,
  analytics: ChartBarFilled,
  export: ExportLine,
  'co-marketing': GamesFilled,
};

const COLORS = [
  'bg-task-type-poll',
  'bg-task-type-file-upload',
  'bg-task-type-api',
  'bg-task-type-url',
  'bg-task-type-visit-link',
  'bg-task-type-text',
];

export const UpgradeModal = (props: ModalProps) => {
  const { subdomain } = useParams<{ subdomain: string }>();
  const t = useTranslations('subscriptions.upgrade-modal');
  const { analytics } = useEventTracker();
  const { data: community } = useCommunity();

  const user = useAuthenticatedUser<CommunityMember>();

  const canUpgrade = user.data?.role === 'admin';

  useEffect(() => {
    if (props.open) {
      analytics.track('Viewed Upgrade Modal', {
        communityId: community?.id,
      });

      analytics.track_links('#upgrade-cta', 'Clicked Upgrade Modal Link', {
        communityId: community?.id,
      });
    }
  }, [props.open, analytics, community?.id]);

  return (
    <Modal {...props} defaultOpen modal={false} hideCloseButton className="z-50">
      <div className="flex flex-col gap-300 items-center justify-center p-500">
        <h3 className="headings-title-t1 max-w-screen-sm text-center">
          {t.rich('title', {
            pink: text => <span className="text-brand-primary">{text}</span>,
          })}
        </h3>
        <div className="grid lg:grid-cols-2 items-center gap-300 w-full max-w-[800px] ">
          {BENEFITS.map((benefit, index) => (
            <a
              className={clsx(
                'flex items-center text-start gap-200 p-150 rounded-component-xl transition-colors bg-component-quaternary outline-none',
                {
                  'hover:bg-component-quaternary-hover':
                    benefit === 'crm' || benefit === 'analytics',
                },
              )}
              key={benefit}
              href={
                benefit === 'crm'
                  ? 'https://www.loom.com/share/61efd8503a4e48f8b69a6101261b23f6'
                  : benefit === 'analytics'
                  ? 'https://www.loom.com/share/f91a0760dfcc4a99b3a06bd6ce09f5ec'
                  : ''
              }
              target={benefit !== 'crm' && benefit !== 'analytics' ? undefined : '_blank'}
              rel="noreferrer noopener"
            >
              <Badge className={COLORS[index]} size="md" icon={BENEFIT_ICONS[benefit]({})} />
              <div>
                <p className="body-component-lg-bold text-primary">
                  {t(`features.${benefit}.title`)}
                </p>
                <p className="body-component-md text-tertiary">
                  {t(`features.${benefit}.description`)}
                </p>
              </div>
            </a>
          ))}
        </div>
        <div className="flex flex-col gap-300 items-center">
          <p className="body-component-lg text-secondary">{t('description')}</p>
          {canUpgrade ? (
            <Button
              as={Link}
              href={`/cw/${subdomain}/settings/plans`}
              id="upgrade-cta"
              isDisabled={!canUpgrade}
            >
              {t('cta')}
            </Button>
          ) : (
            <>
              <Button isDisabled={true} variant={'muted'}>
                {t('cta')}
              </Button>
              <p className="body-component-sm text-secondary">{t('admin-access-required')}</p>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
