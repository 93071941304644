'use client';

import { useFormatter, useTranslations } from 'next-intl';

import { CommunityMember, useAuthenticatedUser } from '@zealy/queries';
import { roleIsAtLeast } from '@zealy/utils';

import { Countdown } from '#components/Countdown/Countdown';
import { useDidMount } from '#hooks/useDidMount';

export const CountdownBar = ({ launchDate }: { launchDate?: string | null }) => {
  const t = useTranslations();
  const format = useFormatter();
  const didMount = useDidMount();
  const user = useAuthenticatedUser<CommunityMember>();

  if (!didMount || roleIsAtLeast(user.data?.role, 'reviewer')) return null;

  return (
    <div className="bg-tertiary rounded-sm overflow-hidden gap-200 py-250 md:py-300 px-200 flex flex-col md:flex-row items-start md:items-center shrink-0">
      <div className="flex flex-1 flex-row">
        <span className="body-paragraph-md-bold text-primary">
          {t('community.join-not-launched-community')}
        </span>
      </div>
      {launchDate && (
        <Countdown
          date={launchDate}
          label={format.dateTime(new Date(launchDate), {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
          })}
        />
      )}
    </div>
  );
};
