'use client';

import { cn, TooltipProvider } from '@zealy/design-system';

import { TailwindIndicator } from '#components/tailwind-indicator';
import { useIsCompanyMember } from '#hooks/useIsCompanyMember';

import { ChangeRole } from './components/ChangeRole/ChangeRole';
import { FpsSection } from './components/Fps/FpsSection';
import { Impersonate } from './components/Impersonate/Impersonate';

export const DevToolbar = () => {
  const isCompanyMember = useIsCompanyMember();

  if (!isCompanyMember) return null;

  return (
    <TooltipProvider>
      <div
        className={cn(
          'bg-secondary rounded-md h-component-xs px-100 flex flex-row gap-100 justify-end items-center w-full z-10',
        )}
      >
        <TailwindIndicator />
        <ChangeRole />
        <Impersonate />
        <FpsSection />
      </div>
    </TooltipProvider>
  );
};
