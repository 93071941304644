'use client';

import { useSessionStorageValue } from '@react-hookz/web';
import React from 'react';
import { useTranslations } from 'next-intl';
import { useParams, usePathname, useRouter, useSearchParams } from 'next/navigation';

import type { ButtonProps } from '@zealy/design-system';
import { Button } from '@zealy/design-system';

export const ConnectButton = (props: ButtonProps) => {
  const t = useTranslations('common');

  const { invitationId, subdomain } = useParams<{ invitationId: string; subdomain?: string }>();
  const searchParams = useSearchParams();
  const inviteId = searchParams.get('invitationId') ?? invitationId;
  const pathname = usePathname();
  const { set: setRedirectUrl } = useSessionStorageValue<string | null>('redirectUrl');
  const router = useRouter();

  const handleClick = () => {
    setRedirectUrl(pathname);

    const href = pathname?.includes('/embed/c/')
      ? '/embed/signup'
      : `/signup?${inviteId ? `invitationId=${inviteId}&` : ''}${
          subdomain ? `subdomain=${subdomain}` : ''
        }`;

    router.push(href);
  };

  return (
    <Button color="cta" variant="filled" {...props} onClick={handleClick}>
      {t('connect-to', {
        platform: t('brandName'),
      })}
    </Button>
  );
};
