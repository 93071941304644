'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, useContext } from 'react';
import { cx } from 'class-variance-authority';
import { OTPInput, OTPInputContext, REGEXP_ONLY_DIGITS, REGEXP_ONLY_DIGITS_AND_CHARS, } from 'input-otp';
import { getValidChildren } from '../../utils/children';
import { getComponentState } from '../../utils/getComponentState';
import { styles } from './OtpField.styles';
export const OtpField = ({ invalid, disabled, success, size, className, children, id, type = 'number', ...props }) => {
    const state = getComponentState({ isDisabled: disabled, isInvalid: invalid, isSuccess: success });
    const clonedElement = getValidChildren(children).map((el, index) => cloneElement(el, { index, state, size }));
    return (_jsx(OTPInput, { id: id, disabled: disabled, containerClassName: cx(styles.root({ size }), className), pattern: type === 'number' ? REGEXP_ONLY_DIGITS : REGEXP_ONLY_DIGITS_AND_CHARS, maxLength: clonedElement.length, inputMode: "text", ...props, children: clonedElement }));
};
// Before calling cloneElement, the <OtpSlot /> component does not explicitly receive
// the index. Thus, typescript yells because it thinks it's incorrect.
// The thing is that we synchronously update the children at runtime
// so we are guaranteed to have an index, event if the default is 0
export const OtpSlot = ({ size, state = 'default', index = 0 }) => {
    const inputOTPContext = useContext(OTPInputContext);
    const { char, isActive, hasFakeCaret } = inputOTPContext.slots[index];
    return (_jsxs("div", { "data-state": isActive ? 'active' : 'inactive', className: cx(styles.input({
            size,
            state,
        })), children: [char, hasFakeCaret && (_jsx("div", { className: "pointer-events-none absolute inset-0 flex items-center justify-center", children: _jsx("div", { className: "h-250 w-12 animate-caret-blink bg-[var(--color-text-primary)]" }) }))] }));
};
