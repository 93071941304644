'use client';

import React from 'react';
import dayjs from 'dayjs';
import { useFormatter, useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';

import type { Community, CommunityMember } from '@zealy/queries';
import {
  Badge,
  PlanLimit,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@zealy/design-system';
import { EyeSlashLine, QuestionLine } from '@zealy/icons';
import { useAuthenticatedUser, useSubscription } from '@zealy/queries';
import { capitalize, roleIsAtLeast } from '@zealy/utils';

import { UpgradeModal } from '#components/UpgradeModal';

export interface PlanCardProps {
  community?: Community;
}

/**
 * The sidebar is a navigation element used on the questboard
 */
export const PlanCard = ({ community }: PlanCardProps) => {
  const t = useTranslations('subscriptions.plans');
  const user = useAuthenticatedUser<CommunityMember>();
  const router = useRouter();
  const { relativeTime } = useFormatter();

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);

  const closeUpgradeModal = () => setIsUpgradeModalOpen(false);

  const subscription = useSubscription({
    enabled: roleIsAtLeast(user.data?.role, 'admin'),
  });

  async function handleClickPlanLimit() {
    if (!community?.planId) {
      setIsUpgradeModalOpen(true);
    } else {
      router.push(`/cw/${community?.subdomain}/settings/plans`);
    }
  }

  const resetDate = subscription.data?.usageResetAt
    ? new Date(subscription.data?.usageResetAt)
    : dayjs().endOf('month').toDate();

  const remainingTime = relativeTime(resetDate, new Date());

  const isUsageExceeded =
    subscription.data?.includedUnits &&
    subscription.data.currentUsage >= subscription.data.includedUnits;

  const interval = subscription.data?.billingPeriod;

  const resetLimitMessage = t(
    interval === 'year' ? 'yearly-usage-resets' : 'monthly-usage-resets',
    {
      formattedDate: remainingTime,
    },
  );

  if (!subscription.data) return null;

  let cta = t('manage-plan');

  if (subscription.data.planName === 'free' || isUsageExceeded) {
    cta = t('upgrade');
  }

  return (
    <>
      <PlanLimit
        plan={t('plan-name', {
          name: capitalize(subscription.data.planName),
        })}
        title={t('claimed-quests')}
        cta={cta}
        currentValue={subscription.data?.currentUsage}
        maxValue={Number(subscription.data.includedUnits)}
        ResetLimitMessage={resetLimitMessage}
        LimitExceededMessage={
          subscription.data.planName === 'free'
            ? t('free-limit-exceeded', {
                formattedDate: remainingTime,
              })
            : resetLimitMessage
        }
        onClickCTA={handleClickPlanLimit}
        additionalDetails={
          !community?.planId ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Badge
                    size={'xs'}
                    icon={<EyeSlashLine />}
                    variant="disabled"
                    description={<QuestionLine size={14} />}
                  >
                    {t('unlisted.title')}
                  </Badge>
                </TooltipTrigger>
                <TooltipContent className="max-w-screen-sm" align="start">
                  {t('unlisted.description')}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : null
        }
        unlisted={community?.planId ? false : true}
      />
      <UpgradeModal open={isUpgradeModalOpen} onOpenChange={closeUpgradeModal} />
    </>
  );
};
