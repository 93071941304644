import { cva } from 'class-variance-authority';
export const cardStyles = cva([
    'text-onComponent-tertiary',
    'relative overflow-hidden box-border',
    'noise',
    'bg-gradient-radial',
    'group',
], {
    variants: {
        variant: {
            default: 'border rounded-component-md border-component-tertiary',
            secondary: 'rounded-sm bg-secondary',
        },
        isClickable: {
            true: 'hover:from-[#ffffff12] hover:border-component-tertiary-hover active:border-component-tertiary-press',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});
