'use client';

import React, { useCallback } from 'react';

import { envConfig } from '#app/config';

type Popup = {
  id: string;
  priority: number;
};

export interface PopupState {
  queue: Popup[];
}

export interface PopupContextState {
  queue: Popup[];
  currentPopupId: string | null;
}

export const PopupContext = React.createContext<{
  state: PopupContextState;
  dispatch: React.Dispatch<PopupProviderAction>;
}>({
  state: { currentPopupId: null, queue: [] },
  dispatch: () => {},
});

export enum PopupAction {
  ADD_POPUP = 'ADD_POPUP',
  REMOVE_POPUP = 'REMOVE_POPUP',
}

export interface PopupProviderAction {
  type: PopupAction;
  payload: Popup;
}

function popupReducer(state: PopupState, action: PopupProviderAction) {
  switch (action.type) {
    case 'ADD_POPUP': {
      const isInQueue = state.queue.some(popup => popup.id === action.payload.id);
      if (isInQueue) return state;

      return {
        queue: [...state.queue, action.payload].sort((a, b) => a.priority - b.priority),
      };
    }
    case 'REMOVE_POPUP': {
      return {
        queue: state.queue.filter(popup => popup.id !== action.payload.id),
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

/**
 * All popups that are not triggered by the user should be added using this
 * provider to ensure that they are not shown at the same time.
 */
export const PopupProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(popupReducer, { queue: [] });

  return (
    <PopupContext.Provider
      value={{
        state: {
          queue: state.queue,
          currentPopupId: state.queue.length ? state.queue[0].id : null,
        },
        dispatch,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};

export const usePopup = ({ id, priority }: { id: string; priority: number }) => {
  const context = React.useContext(PopupContext);
  if (!context) {
    throw new Error('usePopup must be used within a PopupProvider');
  }

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        context.dispatch({
          type: PopupAction.REMOVE_POPUP,
          payload: { id, priority },
        });
      } else {
        context.dispatch({
          type: PopupAction.ADD_POPUP,
          payload: { id, priority },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, priority],
  );

  return {
    isOpen: context.state.currentPopupId === id,
    onOpenChange,
  };
};
