import { cva } from 'class-variance-authority';
export const radioItemWrapperStyles = cva('group flex flex-row items-center justify-center group-hover:cursor-pointer', {
    variants: {
        isDisabled: {
            true: 'hover:cursor-not-allowed',
            false: 'cursor-pointer',
        },
    },
});
export const radioItemContainerStyles = cva('p-control-state-layer box-border select-none transition group-hover:bg-control-state-layer-hover group-active:bg-control-state-layer-press ::before:box-border ::after:box-border rounded-control-radio-button-state-layer leading-none', {
    variants: {
        isDisabled: {
            true: 'group-hover:bg-transparent group-active:bg-transparent group-hover:cursor-not-allowed',
        },
    },
});
export const radioItemStyles = cva("w-control-container-xxxs h-control-container-xxxs pointer-events-auto group-hover:cursor-pointer focus-visible:outline-control-focus focus-visible:outline-control-state-layer-focus focus-visible:outline-2 focus-visible:outline-offset-[5px] flex justify-center items-center border-component-secondary rounded-control-radio-button-container border-control data-[state='checked']:bg-control-container data-[state='checked']:icon-control-primary focus-visible:outline", {
    variants: {
        isDisabled: {
            true: "border-disabled group-hover:cursor-not-allowed data-[state='unchecked']:border-control-container-disabled data-[state='checked']:bg-control-container-disabled data-[state='indeterminate']:bg-control-container-disabled data-[state='checked']:border-none data-[state='indeterminate']:border-none",
            false: '',
        },
    },
});
export const radioIndicatorStyles = cva("flex justify-center items-center w-full h-full relative after:content-[''] after:block after:w-[6.67px] after:h-[6.67px] after:rounded-full after:bg-current icon-control-primary");
export const radioLabelStyles = cva('text-label-lg group-hover:cursor-pointer', {
    variants: {
        isDisabled: {
            false: 'text-control-primary group-hover:cursor-pointer',
            true: 'text-control-disabled pointer-events-none',
        },
    },
});
