import { cx } from 'class-variance-authority';
const root = cx('flex gap-community-selector-avatar items-center group/avatar cursor-pointer box-border group-data-[state=open]/bar:w-auto w-community-selector-avatar');
const avatar = cx('flex h-community-selector-avatar w-community-selector-avatar rounded-community-selector-avatar', 'relative select-none flex-shrink-0', 'transition-all duration-150 ease-in-out', 'ring-offset-[0px]', 'ring-2 ring-transparent', 'group-data-[selected]/avatar:ring-offset-[3px] hover:ring-offset-[3px] active:ring-offset-[3px] group-focus:ring-offset-[3px]', 'group-data-[selected]/avatar:ring-community-selector-avatar-selected ring-offset-[var(--color-bg-secondary)] group-hover/avatar:ring-community-selector-avatar-hover group-active/avatar:ring-community-selector-avatar-press group-focus:ring-community-selector-avatar-focus');
const label = cx('community-selector-community-name text-community-selector-community-name px-component-xs', 'group-data-[state=open]/bar:w-auto hidden group-data-[state=open]/bar:block group-data-[state=open]/bar:opacity-100 w-0 overflow-hidden whitespace-nowrap opacity-0', 'transition-opacity duration-200 ease-in-out', 'select-none');
const badge = cx('absolute -top-25 -right-25 h-community-selector-notification w-community-selector-notification rounded-full bg-community-selector-notification ring-[3px] ring-[var(--color-bg-secondary)]');
export const styles = {
    root,
    avatar,
    label,
    badge,
};
