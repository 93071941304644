import { cva, cx } from 'class-variance-authority';
const root = cx(['flex relative !m-0']);
export const calendarStyles = {
    root,
    caption_label: cx(['date-picker-month']),
    head_cell: cx(['w-date-picker-cell body-paragraph-md text-date-picker-secondary']),
    table: cx(['relative border-separate']),
    head: cx(['table-caption border-b border-b-separators']),
    head_row: cx(['flex justify-evenly']),
    month: cx(['my-0 relative']),
    months: cx(['flex flex-col sm:flex-row space-y-200 sm:space-x-200 sm:space-y-0']),
    cell: cva([
        'relative p-0 text-center text-sm z-0 [&:has([aria-selected])]:bg-date-picker-cell-selected-middle',
        '[&:has(>.day-range-start)]:!rounded-l-date-picker-cell-rounded',
        '[&:has(>.day-range-end)]:!rounded-r-date-picker-cell-rounded',
    ], {
        variants: {
            mode: {
                single: '[&:has([aria-selected])]:rounded-date-picker-cell-rounded',
                multiple: '[&:has([aria-selected])]:rounded-date-picker-cell-rounded',
                default: '[&:has([aria-selected])]:rounded-date-picker-cell-rounded',
                range: [
                    'first:[&:has([aria-selected])]:rounded-l-date-picker-cell-start-end-raw last:[&:has([aria-selected])]:rounded-r-date-picker-cell-start-end-raw',
                ],
            },
        },
        defaultVariants: {
            mode: 'default',
        },
    }),
    day: cx([
        'flex justify-center items-center',
        'hover:!bg-date-picker-cell-selected-middle',
        'focus:!bg-transparent',
        'p-date-picker-cell outline-none border-date-picker focus-within:bg-date-picker-cell-selected-middle focus-within:!border-date-picker-focus active:!border-date-picker-press active:border-date-picker-press rounded-date-picker-cell-rounded aria-selected:opacity-100 z-auto h-date-picker-cell box-border after:box-border before:box-border w-date-picker-cell date-picker-date',
    ]),
    day_today: cx([' bg-date-picker-cell-selected-middle']),
    day_selected: cx([
        'bg-primary hover:bg-primary focus:bg-primary aria-selected:!bg-date-picker-cell-selected',
    ]),
    day_disabled: cx(['text-date-picker-disabled opacity-50']),
    day_hidden: 'invisible',
    day_range_middle: cx(['aria-selected:!bg-transparent']),
    day_range_start: 'day-range-start',
    day_range_end: 'day-range-end',
    caption: cx(['flex justify-center items-center px-150 mb-100']),
    nav: cx(['gap-100 flex items-center']),
    nav_button: cx([
        'hover:!bg-transparent focus-visible:!border-none focus-visible:!bg-transparent',
    ]),
    nav_button_next: cx(['!absolute end-75']),
    nav_button_previous: cx(['!absolute start-75']),
};
