'use client';

import { useTheme } from 'next-themes';

import { IconButton } from '@zealy/design-system';
import { MoonLine, SunLine } from '@zealy/icons';

import { envConfig } from '#app/config';
import { useDidMount } from '#hooks/useDidMount';

const IS_PROD = envConfig.env === 'production';

export const ThemeSwitcher = () => {
  const { theme, setTheme } = useTheme();
  const mounted = useDidMount();

  if (IS_PROD || !mounted) {
    return null;
  }

  const toggleTheme = () => setTheme(theme === 'light' ? 'dark' : 'light');
  const THEMED_ICON = theme === 'light' ? <MoonLine /> : <SunLine />;

  return (
    <IconButton
      mutedText
      tooltip="Switch theme"
      variant="ghost"
      size="xs"
      className="shadow-md"
      icon={THEMED_ICON}
      onClick={toggleTheme}
    />
  );
};
