'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';
import { cn } from '../../utils/cn';
import { styles } from './Popover.styles';
const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverPortal = PopoverPrimitive.Portal;
const PopoverAnchor = PopoverPrimitive.Anchor;
const PopoverClose = PopoverPrimitive.Close;
const PopoverContent = React.forwardRef(({ className, align = 'center', title = '', variant = 'primary', description = '', sideOffset = 4, children, ...props }, ref) => (_jsx(PopoverPrimitive.Portal, { children: _jsxs(PopoverPrimitive.Content, { ref: ref, align: align, sideOffset: sideOffset, className: cn(styles.content({
            variant,
        }), className), ...props, children: [(!!title || !!description) && (_jsxs("div", { className: styles.text, children: [title && _jsx("p", { className: styles.title, children: title }), description && _jsx("p", { className: styles.description, children: description })] })), children] }) })));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
export { Popover, PopoverTrigger, PopoverContent, PopoverPortal, PopoverAnchor, PopoverClose };
