'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { useTranslations } from 'next-intl';
import { Button } from '../Button';
import { TextField } from '../TextField';
export const LinkCopyGroup = ({ size = 'xl', link }) => {
    const t = useTranslations('common');
    const ref = useRef(null);
    const copy = () => {
        if (!ref.current)
            return;
        const range = document.createRange();
        ref.current.readOnly = false;
        range.selectNodeContents(ref.current);
        const s = window.getSelection();
        s?.removeAllRanges();
        s?.addRange(range);
        ref.current.setSelectionRange(0, 200); // A big number, to cover anything that could be inside the element.
        ref.current.readOnly = true;
        document.execCommand('copy');
        navigator.clipboard.writeText(link);
    };
    return (_jsxs("div", { className: "flex", children: [_jsx(TextField, { readOnly: true, value: link, name: "link", size: size, className: "rounded-r-none", ref: ref }), _jsx(Button, { color: "cta", size: size, onClick: copy, className: "rounded-l-none", type: "button", children: t('copy') })] }));
};
