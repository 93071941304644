import { jsx as _jsx } from "react/jsx-runtime";
import { cn } from '../../utils/cn';
import { cardStyles as styles } from './Card.styles';
const gradientColors = {
    green: '90,215,105',
    purple: '140, 141, 252',
    pink: '209, 138, 255',
    yellow: '222, 170, 66',
    cyan: '135, 215, 243',
    white: '255, 255, 255',
};
export const Card = ({ as: As = 'div', className, children, color = 'green', variant = 'default', ...props }) => {
    return (_jsx(As, { ...props, className: cn(styles({ variant, isClickable: Boolean(props.onClick) }), props.onClick ? 'cursor-pointer' : undefined, className), children: children }));
};
