'use client';

import type { Community, CommunityMember } from '@zealy/queries';
import { isUserConnected, useAuthenticatedUser } from '@zealy/queries';
import { roleIsAtLeast } from '@zealy/utils';

import { CommunityProfile } from '#/components/CommunityProfile';
import { useDidMount } from '#hooks/useDidMount';

import { CommunityActions } from './CommunityActions';

type CommunityInfoOverlayProps = {
  community: Community;
};

export const CommunityInfoOverlay = ({ community }: CommunityInfoOverlayProps) => {
  const { data: user, isLoading } = useAuthenticatedUser<CommunityMember>(community.subdomain);
  const didMount = useDidMount();

  const isNotLaunched =
    !(community?.launchDate && new Date(community?.launchDate) <= new Date()) &&
    (!isUserConnected() || (!isLoading && !roleIsAtLeast(user?.role, 'reviewer')));

  const isPrivate = community?.visibility === 'private' && !user?.role;

  const isBanned = user?.role === 'banned';

  const shouldShowOverlay = isNotLaunched || isPrivate || isBanned;

  if (!didMount || !shouldShowOverlay) return null;

  return (
    <div className="w-full min-h-0 flex-1 bg-blackA4 backdrop-blur-lg flex flex-col items-center justify-center rounded-md overflow-hidden gap-400 px-200 absolute inset-0 z-50">
      <CommunityProfile {...{ community }} />
      <CommunityActions {...{ community, user }} />
    </div>
  );
};
