'use client';
import { createContext } from '../../utils/component.context';
export const [ProgressBarContextProvider, useProgressBarContext] = createContext({
    name: 'ProgressBarContext',
    strict: true,
    hookName: 'useProgressBarContext',
    providerName: 'ProgressBarProvider',
    defaultValue: {
        size: 'md',
        variant: 'neutral',
        max: 0,
        value: 0,
    },
});
