import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fallback, Image, Root } from '@radix-ui/react-avatar';
import { cn } from '../../../utils/cn';
import { Skeleton as SkeletonText } from '../../Skeleton';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../Tooltip';
import { styles } from './CommunityAvatar.styles';
const Avatar = ({ src, name, className, badge }) => (_jsxs(Root, { className: cn(styles.avatar, className), children: [_jsx(Image, { src: src ?? undefined, alt: name, className: "object-cover h-full w-full overflow-hidden rounded-community-selector-avatar", draggable: false }), _jsx(Fallback, { className: "leading-1 flex h-full w-full items-center justify-center text-button-onneutral-muted-tertiary bg-button-neutral-muted rounded-community-selector-avatar", delayMs: 400, children: name?.[0] }), badge && _jsx("div", { "data-testid": "badge", className: styles.badge })] }));
const Label = ({ name, className }) => (_jsx("p", { className: cn(styles.label, className), children: name }));
export const CommunityAvatar = ({ className, src, name, selected, badge, ...props }) => {
    return (_jsxs("div", { className: cn(styles.root, className), "data-selected": selected || undefined, "data-testid": "community-avatar", ...props, children: [_jsxs(Tooltip, { children: [_jsx(TooltipTrigger, { className: "focus:outline-none group", children: _jsx(Avatar, { src, name, badge }) }), _jsx(TooltipContent, { side: "right", children: name })] }), _jsx(Label, { src, name, badge })] }));
};
const Skeleton = ({ className }) => (_jsxs("div", { className: cn(styles.root, className), "data-testid": "community-avatar-skeleton", children: [_jsx("div", { className: cn('animate-pulse bg-skeleton', styles.avatar, 'ring-offset-0', className) }), _jsx(SkeletonText, { className: cn(styles.label, 'group-data-[state=open]/bar:w-1000 h-200') })] }));
CommunityAvatar.Skeleton = Skeleton;
