'use client';

import { useMemo } from 'react';
import { useCopyToClipboard } from 'react-use';
import { useTranslations } from 'next-intl';
import { useParams, useRouter, useSearchParams } from 'next/navigation';

import type { Community, CommunityMember } from '@zealy/queries';
import { Button } from '@zealy/design-system';
import { CheckLine } from '@zealy/icons';
import {
  communityKeys,
  joinCommunity,
  useInvitation,
  useReferralLink,
  usersKeys,
} from '@zealy/queries';
import { createInviteURL, roleIsAtLeast } from '@zealy/utils';

import { queryClient } from '#app/QueryProvider';
import { ConnectButton } from '#components/JoinCommunityBar/ConnectButton';
import { revalidateTags } from '#utils/serverAction';

export type CommunityActionsProps = {
  community: Community;
  user?: CommunityMember;
};

export const CommunityActions = ({ user, community }: CommunityActionsProps) => {
  const t = useTranslations('common');

  const { invitationId } = useParams<{ invitationId: string }>();
  const searchParams = useSearchParams();
  const inviteId = searchParams.get('invitationId') ?? invitationId;
  const { data: invite } = useInvitation({ id: inviteId });
  const validInvite = invite?.status === 'valid' && !roleIsAtLeast(user?.role, invite?.role);

  const isPrivate = community?.visibility === 'private' && !validInvite;

  const isBanned = user?.role === 'banned';

  const router = useRouter();

  const { data: referral } = useReferralLink(community.subdomain);
  const [copyState, copy] = useCopyToClipboard();

  const isLinkCopied = !!copyState.value;

  const handleCta = async () => {
    if (user) {
      try {
        await joinCommunity(community.subdomain, inviteId);
      } catch (error) {
        console.error((error as any).response);
      } finally {
        // Doing it here because acceptInvitation can throw and still join
        revalidateTags([`user:${user.id}`]);
        await queryClient.invalidateQueries({ queryKey: usersKeys.user('me') });
        await queryClient.invalidateQueries({ queryKey: communityKeys.userCommunities() });
      }
    } else router.push(`/login${inviteId ? `?invitationId=${inviteId}` : ''}`);
  };

  const isJoined = Boolean(user?.role) && !validInvite;

  const ctaVariant = isJoined ? 'ghost' : 'filled';
  const ctaLabel = isJoined ? t('joined') : t('join-community', { communityName: community.name });

  const label = isLinkCopied ? t('link-copied') : t('invite-friends');

  const inviteLink = useMemo(() => createInviteURL(referral?.id ?? ''), [referral?.id]);

  const description = isBanned ? (
    <>
      {t('banned')} <br /> <br />
      {!!user.banReason && t('ban-reason', { reason: user?.banReason })}
    </>
  ) : isPrivate ? (
    t('private')
  ) : undefined;

  const handleCopy = () => {
    copy(inviteLink);
  };

  const canJoin = !isJoined && !isBanned && !isPrivate;

  return (
    <>
      <div className="flex flex-row gap-200">
        {user ? (
          <Button variant={ctaVariant} isDisabled={!canJoin} color="cta" onClick={handleCta}>
            {ctaLabel}
          </Button>
        ) : (
          <ConnectButton>
            {t('connect-to', {
              platform: t('brandName'),
            })}
          </ConnectButton>
        )}
        <Button
          onClick={handleCopy}
          variant="muted"
          isDisabled={!isJoined || !inviteLink}
          leftIcon={isLinkCopied ? <CheckLine /> : undefined}
        >
          {label}
        </Button>
      </div>
      {description && (
        <p className="body-component-md text-secondary max-w-[402px] text-center">{description}</p>
      )}
    </>
  );
};
