'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import 'react-day-picker/dist/style.css';
import { DayPicker } from 'react-day-picker';
import { ChevronLeftLine, ChevronRightLine } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { buttonStyles } from '../Button/Button.styles';
import { calendarStyles as styles } from './Calendar.styles';
export const Calendar = ({ className, classNames, showOutsideDays = true, ...props }) => {
    return (_jsx(DayPicker, { showOutsideDays: showOutsideDays, className: cn(styles.root, className), classNames: {
            caption_label: styles.caption_label,
            head_cell: styles.head_cell,
            table: styles.table,
            cell: styles.cell({
                ...(props.mode && {
                    mode: props.mode,
                }),
            }),
            day_selected: styles.day_selected,
            day: styles.day,
            head: styles.head,
            caption: styles.caption,
            nav: styles.nav,
            month: styles.month,
            months: styles.months,
            nav_button: cn(styles.nav_button, buttonStyles({
                size: 'sm',
                variant: 'muted',
                onlyIcon: true,
            })),
            head_row: styles.head_row,
            day_disabled: styles.day_disabled,
            day_range_end: styles.day_range_end,
            day_range_start: styles.day_range_start,
            day_range_middle: styles.day_range_middle,
            nav_button_previous: styles.nav_button_previous,
            nav_button_next: styles.nav_button_next,
            ...classNames,
        }, components: {
            IconLeft: () => _jsx(ChevronLeftLine, { className: "h-4 w-4" }),
            IconRight: () => _jsx(ChevronRightLine, { className: "h-4 w-4" }),
            ...props?.components,
        }, ...props }));
};
