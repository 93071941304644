import { useEffect, useState } from 'react';

export const useFps = (gcTimeInS: number) => {
  const [fps, setFps] = useState(60);
  const [fpsArray, setFpsArray] = useState<Array<number>>([]);

  useEffect(() => {
    let frameCount = 0;
    let lastTime = performance.now();

    const interval = 1000; // Update FPS every second

    const updateFPS = () => {
      const currentTime = performance.now();
      frameCount++;

      if (currentTime - lastTime >= interval) {
        const currentFps = frameCount / ((currentTime - lastTime) / 1000);

        frameCount = 0;
        lastTime = currentTime;
        const roundedFps = Math.round(currentFps);

        setFps(roundedFps);
        setFpsArray(s => {
          const newHistory = [...s, roundedFps];
          if (newHistory.length > gcTimeInS) {
            newHistory.shift(); // Remove the oldest FPS value to keep the history length at 60
          }
          return newHistory;
        });
      }

      requestAnimationFrame(updateFPS);
    };

    const frameId = requestAnimationFrame(updateFPS);
    return () => {
      cancelAnimationFrame(frameId);
    };
  }, [gcTimeInS]);

  return { fps, fpsArray };
};
