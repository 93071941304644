import { cva } from 'class-variance-authority';
export const root = cva(['flex flex-col w-full z-0'], {
    variants: {
        size: {
            sm: 'gap-progress-bar-sm-y',
            md: 'gap-progress-bar-md-y',
            lg: 'gap-progress-bar-lg-y',
        },
    },
    compoundVariants: [],
    defaultVariants: {
        size: 'sm',
    },
});
export const labelgroup = cva(['flex justify-between items-center z-[1]']);
export const labelcontainer = cva(['flex justify-between items-center gap-50 text-progress-bar-label-secondary'], {
    variants: {
        size: {
            sm: 'progress-bar-sm',
            md: 'progress-bar-md',
            lg: 'progress-bar-lg',
        },
    },
    defaultVariants: {
        size: 'sm',
    },
});
export const stats = cva(['text-progress-bar-label-primary'], {
    variants: {
        size: {
            sm: 'progress-bar-sm',
            md: 'progress-bar-md',
            lg: 'progress-bar-lg',
        },
    },
    defaultVariants: {
        size: 'sm',
    },
});
export const statsicon = cva(['text-progress-bar-label-primary'], {
    variants: {
        size: {
            sm: 'h-progress-bar-icon-sm w-progress-bar-icon-sm',
            md: 'h-progress-bar-icon-md w-progress-bar-icon-md',
            lg: 'h-progress-bar-icon-lg w-progress-bar-icon-lg',
        },
    },
    defaultVariants: {
        size: 'sm',
    },
});
export const wrapper = cva(['relative']);
export const rewardcontainer = cva(['absolute right-0 top-[50%] -translate-y-[50%] flex justify-center items-center'], {
    variants: {
        size: {
            sm: 'max-h-[20px] max-w-[25px]',
            md: 'max-h-[22px] max-w-[28px]',
            lg: 'max-h-[28px] max-w-[38px]',
        },
    },
});
export const rewardimage = cva(['shadow-[0_0_0_4px_var(--progress-bar-fill-shadow-bg)]']);
export const track = cva([
    'bg-progress-bar-background rounded-progress-bar-rounded relative overflow-hidden w-full translate-z-[0]]',
], {
    variants: {
        size: {
            sm: 'h-progress-bar-sm data-[reward]:w-[calc(100%-var(--progress-bar-shadow-thickness)-21px)]',
            md: 'h-progress-bar-md data-[reward]:w-[calc(100%-var(--progress-bar-shadow-thickness)-23px)]',
            lg: 'h-progress-bar-lg data-[reward]:w-[calc(100%-var(--progress-bar-shadow-thickness)-31px)]',
        },
    },
    defaultVariants: {
        size: 'sm',
    },
});
export const fill = cva(['h-full rounded-progress-bar-rounded transition ease-in-out duration-300 relative z-0'], {
    variants: {
        size: {
            sm: 'shadow-[0_0_0_var(--progress-bar-shadow-thickness)_var(--progress-bar-fill-shadow-bg)]',
            md: 'shadow-[0_0_0_var(--progress-bar-shadow-thickness)_var(--progress-bar-fill-shadow-bg)]',
            lg: 'shadow-[0_0_0_var(--progress-bar-shadow-thickness)_var(--progress-bar-fill-shadow-bg)]',
        },
        variant: {
            neutral: 'bg-progress-bar-loading-neutral',
            brand: 'bg-progress-bar-loading-brand',
        },
    },
    defaultVariants: {
        size: 'md',
        variant: 'neutral',
    },
});
export const progressBar = {
    root,
    labelgroup,
    labelcontainer,
    stats,
    statsicon,
    track,
    fill,
    rewardcontainer,
    rewardimage,
    wrapper,
};
