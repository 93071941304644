import { cva, cx } from 'class-variance-authority';
const root = cva([
    'flex flex-col-reverse @xl:!flex-row bg-embed-url border-embed-url rounded-embed-url-container p-embed-url-container gap-embed-url-container',
    'focus-visible:ring-embed-url-focus outline-none box-border',
    'hover:border-embed-url-hover active:border-embed-url-press hover:bg-component-quaternary-hover',
    'items-stretch w-full',
], {
    variants: {
        asTask: {
            true: 'rounded-embed-url-container-task',
        },
    },
});
const image = cva('object-cover aspect-video rounded-embed-url-img overflow-hidden w-full @xl:max-w-[212px]', {
    variants: {
        asTask: {
            true: 'rounded-embed-url-img-task bg-embed-url-task',
        },
    },
});
const textContainer = cx('flex flex-col gap-embed-url-texts flex-1 w-full p-embed-url-container');
const title = cx('embed-url-title text-embed-url-primary');
const description = cx('embed-url-description text-embed-url-secondary');
const favicon = cx('h-icon-lg w-icon-lg');
const url = cx('embed-url-url text-embed-url-primary !mt-0 min-w-0 whitespace-pre-line overflow-hidden');
const urlContainer = cx('flex gap-100 items-center min-w-0');
export const styles = {
    root,
    image,
    textContainer,
    title,
    description,
    urlContainer,
    favicon,
    url,
};
