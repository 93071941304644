'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Primitive from '@radix-ui/react-progress';
import { forwardRef, useMemo } from 'react';
import { match } from '@zealy/utils';
import { cn } from '../../utils/cn';
import { ProgressBarContextProvider, useProgressBarContext } from './ProgressBar.context';
import { progressBar } from './ProgressBar.styles';
/**
 * The root component of the ProgressBar.
 */
const Root = forwardRef(({ className, size = 'md', variant = 'neutral', max, fillShadowBg = 'var(--color-bg-primary)', value, children, ...props }, ref) => {
    const style = useMemo(() => ({
        '--progress-bar-fill-shadow-bg': fillShadowBg,
    }), [fillShadowBg]);
    return (_jsx(ProgressBarContextProvider, { value: {
            size,
            variant,
            max,
            value,
        }, children: _jsx("div", { ref: ref, className: cn(progressBar.root({ size }), className), "data-scope": "progressbar", "data-part": "root", style: style, ...props, children: children }) }));
});
Root.displayName = 'ProgressBarRoot';
const LabelGroup = forwardRef(({ className, children, ...props }, ref) => {
    return (_jsx("div", { ref: ref, className: cn(progressBar.labelgroup(), className), "data-scope": "progressbar", "data-part": "labelgroup", ...props, children: children }));
});
LabelGroup.displayName = 'ProgressBarLabelGroup';
/**
 * The label component is used to display the text labels such as the stats and the description.
 */
const Label = forwardRef(({ className, children, ...props }, ref) => {
    const { size } = useProgressBarContext();
    return (_jsx("div", { ref: ref, className: cn(progressBar.labelcontainer({ size }), className), "data-scope": "progressbar", "data-part": "label", ...props, children: children }));
});
Label.displayName = 'ProgressBarLabel';
const Stats = forwardRef(({ className, children, ...props }, ref) => {
    const { size } = useProgressBarContext();
    return (_jsx("span", { ref: ref, className: cn(progressBar.stats({ size }), className), "data-scope": "progressbar", "data-part": "stats", ...props, children: children }));
});
Stats.displayName = 'ProgressBarStats';
/**
 * The Track component contains the actual progress element of the ProgressBar component.
 */
const Track = forwardRef(({ className, rewardImage, trackColor, ...props }, ref) => {
    const { size, max, value, variant } = useProgressBarContext();
    const computedValue = (value / max) * 100;
    const fillGutterSize = match(size, {
        sm: '4px',
        md: '5px',
        lg: '6px',
    });
    const gutterDelta = `${computedValue === 0 ? fillGutterSize : '0px'}`;
    const indicatorStyle = useMemo(() => ({
        transform: `translateX(calc(-${100 - computedValue}% - ${gutterDelta}))`,
        backgroundColor: trackColor,
    }), [gutterDelta, trackColor, computedValue]);
    const rootStyle = useMemo(() => ({
        '--progress-bar-shadow-thickness': fillGutterSize,
    }), [fillGutterSize]);
    return (_jsxs("div", { className: progressBar.wrapper(), children: [_jsx(Primitive.Root, { ref: ref, className: cn(progressBar.track({ size }), className), "data-scope": "progressbar", "data-part": "track", "data-reward": rewardImage, value: computedValue, style: rootStyle, ...props, children: _jsx(Primitive.Indicator, { "data-scope": "progressbar", "data-part": "fill", className: cn(progressBar.fill({ variant, size })), style: indicatorStyle }) }), rewardImage && _jsx(ProgressBarAnatomy.Reward, { imageUrl: rewardImage })] }));
});
Track.displayName = 'ProgressBarTrack';
/**
 * The Reward component is used to display the reward image. If no image is provided it should not be rendered.
 */
const Reward = forwardRef(({ className, children, imageUrl, ...props }, ref) => {
    const { size } = useProgressBarContext();
    return (_jsx("div", { className: cn(progressBar.rewardcontainer({ size }), className), "data-scope": "progressbar", "data-part": "rewardcontainer", ...props, children: _jsx("img", { ref: ref, "data-scope": "progressbar", "data-part": "rewardimage", className: cn(progressBar.rewardimage(), className), src: imageUrl, alt: "Reward" }) }));
});
Reward.displayName = 'ProgressBarReward';
export const ProgressBarAnatomy = {
    Root,
    LabelGroup,
    Label,
    Stats,
    Track,
    Reward,
};
