'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, useMemo } from 'react';
import { ArrowLeftLine, CrossLine, Menu2Line } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { Avatar } from '../Avatar';
import { Button } from '../Button';
import { Dialog, DialogDrawerContent, DialogTrigger } from '../Dialog';
import { mobileNavbarStyles as styles } from './MobileNavbar.styles';
export const MobileNavbar = ({ className, rightIcon, communityAvatarSrc, communityName, onClickRightIcon, showBackArrow, onClickBackArrow, onClickCommunityAvatar, navbarHeight = 52, children, open, onOpenChange, ...props }) => {
    const navHeightToPixelString = `${navbarHeight}px`;
    const style = useMemo(() => ({
        '--mobile-navbar-height': navHeightToPixelString,
    }), [navHeightToPixelString]);
    return (_jsx(Dialog, { open: open, onOpenChange: onOpenChange, "data-testid": "mobile-sidebar", children: _jsxs("div", { className: "relative", children: [_jsxs("nav", { className: cn(styles.root({}), className), ...props, children: [_jsxs("div", { className: "flex items-center gap-50", children: [showBackArrow && (_jsx(Button, { variant: "ghost", onlyIcon: true, leftIcon: _jsx(ArrowLeftLine, { size: 24 }), "data-testid": "backarrow", onClick: onClickBackArrow, "aria-label": "Back" })), _jsx(DialogTrigger, { asChild: true, children: _jsx(Button, { variant: "ghost", onlyIcon: true, leftIcon: open ? (_jsx(CrossLine, { className: "flex-shrink-0", size: 24 })) : (_jsx(Menu2Line, { className: "flex-shrink-0", size: 24 })), "aria-label": "Toggle menu" }) }), _jsxs("div", { className: "flex items-center gap-100 w-full", onClick: onClickCommunityAvatar, children: [_jsx(Avatar, { "data-testid": "communityavatar", src: communityAvatarSrc, name: communityName, size: "sm", className: "!rounded-component-xs text-primary" }), _jsx("h4", { "data-testid": "header", className: "body-component-md-bold", children: communityName })] })] }), rightIcon && (_jsx("div", { "data-testid": "righticon", onClick: onClickRightIcon, children: cloneElement(rightIcon, {
                                size: 24,
                            }) }))] }), _jsx(DialogDrawerContent, { placement: "left", style: style, className: '!top-[var(--mobile-navbar-height)] !h-[calc(100svh-var(--mobile-navbar-height))] w-auto bg-transparent outline-none p-0 border-0 flex', onOpenAutoFocus: e => e.preventDefault(), children: children })] }) }));
};
