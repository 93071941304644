'use client';

// doing that to fix an issue: https://github.com/snelsi/next-recaptcha-v3/issues/135
import { useCallback, useEffect, useState } from 'react';
import { useReCaptcha as useReCaptchaLib } from 'next-recaptcha-v3';

export const useReCaptcha = (action: string) => {
  const { executeRecaptcha, loaded } = useReCaptchaLib();

  const [reCaptchaToken, setReCaptchaToken] = useState<string | undefined>();

  const reloadReCaptcha = useCallback(async () => {
    if (!loaded) {
      return;
    }
    const token = await executeRecaptcha(action);
    setReCaptchaToken(token);
  }, [loaded, executeRecaptcha, action]);

  useEffect(() => {
    reloadReCaptcha();
  }, [reloadReCaptcha]);

  return { reCaptchaToken, reloadReCaptcha };
};
