'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslations } from 'next-intl';
import { CrossLine } from '@zealy/icons';
import { Button } from '../Button/Button';
import { Dialog, DialogClose, DialogContent, DialogTitle, DialogTrigger } from '../Dialog';
import { OptionCard, SubOptionCard } from './ReportCommunityOptions';
const Options = {
    suspicious: ['fraud', 'fake', 'impersonation', 'misinformation'],
    violence: ['hateful', 'violence', 'nudity'],
    other: ['broken', 'dislike'],
};
export function ReportCommunityDialog({ children, onSubmitReportCommunity, ...props }) {
    const t = useTranslations('sidebar.report-community');
    const [category, setCategory] = useState();
    const [suboption, setSuboption] = useState();
    const reset = () => {
        setCategory(undefined);
        setSuboption(undefined);
    };
    const [isSubmitting, setIsSubmitting] = useState(false);
    async function handleClickSubmit() {
        if (!suboption)
            return;
        try {
            setIsSubmitting(true);
            await onSubmitReportCommunity?.(suboption);
            // Do not catch, let callback handle exceptions that are lifted
        }
        finally {
            setIsSubmitting(false);
            reset();
            props.onOpenChange?.(false);
        }
    }
    return (_jsxs(Dialog, { ...props, children: [_jsx(DialogTrigger, { "data-testid": "report-community-trigger", asChild: true, children: children }), _jsx(DialogContent, { className: "min-w-[320px] md:min-w-[400px]", children: _jsxs("div", { className: "py-200 px-300 flex flex-col gap-100 relative font-headline max-w-[400px]", children: [_jsx(DialogClose, { asChild: true, children: _jsx(Button, { onlyIcon: true, leftIcon: _jsx(CrossLine, {}), size: "sm", className: "absolute top-150 right-150", variant: "ghost" }) }), _jsx(DialogTitle, { "data-testid": "report-community-title", className: "headings-title-t1", children: t('title') }), _jsx("p", { "data-testid": "report-community-description", className: "body-component-lg mb-200", children: category ? t(`options.${category}.question`) : t('description') }), category
                            ? Options[category].map(option => (_jsx(SubOptionCard, { "data-testid": `suboption:${option}`, onClick: () => setSuboption(option), isChecked: suboption === option, 
                                // @ts-ignore
                                label: t(`options.${category}.suboptions.${option}.label`), 
                                // @ts-ignore
                                description: t(`options.${category}.suboptions.${option}.description`) }, option)))
                            : Object.keys(Options).map(c => (_jsx(OptionCard, { "data-testid": `category:${c}`, onClick: () => setCategory(c), children: t(`options.${c}.label`) }, c))), category && (_jsxs("footer", { className: "flex gap-100 items-center justify-end", children: [_jsx(Button, { size: "sm", variant: 'muted', "data-testid": "report-community-cancel", onClick: reset, children: t('actions.back') }), _jsx(Button, { size: "sm", isDisabled: !suboption, onClick: handleClickSubmit, loading: isSubmitting, "data-testid": "report-community-submit", children: t('actions.submit') })] }))] }) })] }));
}
