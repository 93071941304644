'use client';

import React, { useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { useParams, usePathname, useRouter, useSearchParams } from 'next/navigation';

import type { Community, CommunityMember } from '@zealy/queries';
import {
  AlertDialogContent,
  AlertDialogRoot,
  MobileNavbar as MobileNavbarComponent,
  ReportCommunityDialog,
} from '@zealy/design-system';
import { useAuthenticatedUser, useReportCommunity } from '@zealy/queries';

import { leaveCommunity } from '#components/Sidebar/components/CommunityHeader/CommunityActions.hooks';
import { CommunityInfo } from '#components/Sidebar/components/CommunityHeader/CommunityInfo';

export function AppBar({
  children,
  community,
}: {
  children?: React.ReactNode;
  community: Community;
}) {
  const { data: user } = useAuthenticatedUser<CommunityMember>();
  const t = useTranslations('sidebar.menu');
  const pathname = usePathname();
  const [open, setOpen] = React.useState(false);
  const { subdomain } = useParams<{ subdomain: string }>();

  const showBackArrow = pathname.includes('questboard') && pathname.split('/').length - 1 > 4;

  const router = useRouter();

  const onBackClick = () => {
    if (subdomain) router.push(`/cw/${subdomain}/questboard`);
    else router.push('.');
  };

  const search = useSearchParams();

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  const openReportCommunityDialog = search?.get('show-report') === 'true';
  const openCommunityInfo = search?.get('show-info') === 'true';
  const openLeaveCommunity = search?.get('show-leave') === 'true';

  const hideModal = (name: 'show-report' | 'show-info' | 'show-leave') => (open: boolean) => {
    const newSearch = new URLSearchParams(search);
    newSearch.delete(name);
    if (!open) router.push(`?${newSearch.toString()}`);
  };

  const reportCommunity = useReportCommunity();

  function handleSubmitReportCommunity(reason: string) {
    try {
      reportCommunity.mutateAsync(reason);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <MobileNavbarComponent
        showBackArrow={showBackArrow}
        communityName={community.name}
        communityAvatarSrc={community.image ?? ''}
        onClickBackArrow={onBackClick}
        onClickCommunityAvatar={() => router.push('?show-info=true')}
        open={open}
        onOpenChange={setOpen}
      >
        <div className="flex gap-100 flex-1 h-full max-h-[100svh] overflow-hidden p-100">
          {children}
        </div>
      </MobileNavbarComponent>
      <div className="sm:ml-0 sm:my-0 md:ml-100 md:my-100 hidden md:flex gap-100">{children}</div>
      <ReportCommunityDialog
        open={openReportCommunityDialog}
        onOpenChange={hideModal('show-report')}
        onSubmitReportCommunity={handleSubmitReportCommunity}
      />

      {community && (
        <CommunityInfo
          user={user}
          community={community}
          open={openCommunityInfo}
          onOpenChange={hideModal('show-info')}
        />
      )}

      <AlertDialogRoot open={openLeaveCommunity} onOpenChange={hideModal('show-leave')}>
        <AlertDialogContent
          description={t('leave-private', {
            communityName: community.name,
          })}
          onConfirm={async () => {
            if (!community) return;
            await leaveCommunity(user?.id, community);
            hideModal('show-leave')(false);
          }}
        />
      </AlertDialogRoot>
    </>
  );
}
