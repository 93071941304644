import { Button } from '@zealy/design-system';
import { RulerLine } from '@zealy/icons';

import { envConfig } from '#app/config';
import { ThemeSwitcher } from '#components/theme-switcher';

/**
 * Displays current Tailwind breakpoint
 */
export function TailwindIndicator() {
  if (envConfig.env === 'production') return null;

  return (
    <div className="flex gap-50 rounded-component-xs text-secondary">
      <Button size="xs" className="font-mono" variant="ghost" mutedText leftIcon={<RulerLine />}>
        <span className="block sm:hidden">xs</span>
        <span className="hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden">sm</span>
        <span className="hidden md:block lg:hidden xl:hidden 2xl:hidden">md</span>
        <span className="hidden lg:block xl:hidden 2xl:hidden">lg</span>
        <span className="hidden xl:block 2xl:hidden">xl</span>
        <span className="hidden 2xl:block 3xl:hidden">2xl</span>
        <span className="hidden 3xl:block 4xl:hidden">3xl</span>
        <span className="hidden 4xl:block">4xl</span>
      </Button>
      <ThemeSwitcher />
    </div>
  );
}
