'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TikTokEmbed, TwitterEmbed } from 'react-social-media-embed';
import { cn } from '../../utils/cn';
import { Skeleton } from '../Skeleton';
import { TaskCard } from '../TaskCard';
import { styles } from './EmbedURL.styles';
const LinkSkeleton = () => (_jsx("div", { className: "@container", children: _jsxs("div", { className: cn(styles.root()), children: [_jsxs("div", { className: styles.textContainer, children: [_jsx(Skeleton, { className: "h-200 w-[220px] mb-100" }, "title"), _jsx(Skeleton, { className: "h-150 w-full" }, "description-1"), _jsx(Skeleton, { className: "h-150 w-[220px] mb-100" }, "description-2"), _jsxs("div", { className: styles.urlContainer, children: [_jsx(Skeleton, { className: "w-200 h-200" }, "favicon"), ' ', _jsx(Skeleton, { className: "w-[100px] h-150" }, "url")] })] }), _jsx("div", { className: cn(styles.image(), 'animate-pulse bg-skeleton') }, "image")] }) }));
export const isEmbeddable = (url) => {
    const youtubeMatch = url.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|shorts\/|v\/))([\w\-_]+)/);
    if (youtubeMatch) {
        const startTime = url.match(/(\?|&)(start|t)=(\d+)s?/);
        return {
            type: 'youtube',
            id: youtubeMatch[1],
            startTime: startTime ? startTime[3] : undefined,
        };
    }
    const tikTokMatch = url.match(/tiktok\.com\/@[^/]+\/video\/(\d+)/);
    if (tikTokMatch)
        return {
            type: 'tiktok',
            id: tikTokMatch[1],
        };
    const twitterPostMatch = url.match(/(?:twitter\.com\/|x\.com\/)(?:[^/]+\/status\/)?(\d+)/);
    if (twitterPostMatch)
        return {
            type: 'twitter',
            id: twitterPostMatch[1],
        };
    const loomMatch = url.match(/loom\.com\/share\/([\w\-?_]+)/);
    if (loomMatch)
        return {
            type: 'loom',
            id: loomMatch[1],
        };
};
export const EmbedURL = ({ className, image, url, title, description, favicon, asTask = false, loading, ...props }) => {
    const Component = asTask ? TaskCard : 'div';
    if (loading)
        return _jsx(LinkSkeleton, {});
    // Function to detect YouTube or TikTok URLs and return an embeddable content component
    const renderEmbeddedContent = (uri) => {
        const match = isEmbeddable(uri);
        switch (match?.type) {
            case 'youtube':
                return (_jsx("iframe", { title: "YouTube video", className: "aspect-video rounded-component-sm bg-primary mx-auto w-full", src: `https://www.youtube.com/embed/${match.id}${match.startTime ? `?start=${match.startTime}` : ''}`, allowFullScreen: true }));
            case 'loom':
                return (_jsx("div", { className: "w-full relative h-0 aspect-video pb-[65%]", children: _jsx("iframe", { src: `https://www.loom.com/embed/${match.id}`, className: "absolute left-0 top-0 h-full w-full", allowFullScreen: true }) }));
            case 'tiktok':
                return (_jsx("div", { className: "w-full flex justify-center", children: _jsx(TikTokEmbed, { url: uri }) }));
            case 'twitter':
                return (_jsx("div", { className: "w-full flex justify-center", children: _jsx(TwitterEmbed
                    // @ts-ignore - it does exist idk why it gives ts error
                    , { 
                        // @ts-ignore - it does exist idk why it gives ts error
                        style: {
                            maxWidth: 550,
                        }, url: uri, width: "100%" }) }));
            default:
                return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.textContainer, children: [_jsx("p", { className: styles.title, children: title }), _jsx("p", { className: styles.description, children: description }), _jsxs("div", { className: styles.urlContainer, children: [favicon && _jsx("img", { className: styles.favicon, src: favicon, alt: title }), _jsx("p", { className: styles.url, children: url })] })] }), image && _jsx("img", { className: styles.image({ asTask }), src: image, alt: title })] }));
        }
    };
    return (_jsx(Component, { type: "visitLink", title: "Visit link", className: "@container", children: _jsx("a", { className: cn(styles.root({ asTask }), className), ...props, href: url, children: renderEmbeddedContent(url) }) }));
};
