import { useMemo } from 'react';
import { useCopyToClipboard } from 'react-use';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';

import {
  BookOpenLine,
  DiscordBrand,
  EmailLine,
  FlagLine,
  GearLine,
  Link2Line,
  LogOutLine,
  TwitterFilled,
} from '@zealy/icons';
import { Community, communityKeys, CommunityMember, useReferralLink } from '@zealy/queries';
import { getCommunityPath, roleIsAtLeast } from '@zealy/utils';

import { envConfig } from '#app/config';
import { queryClient } from '#app/QueryProvider';
import { toast } from '#components/Toaster';
import { getTwitterAccountURL } from '#utils/urls';

import { leave } from './Community.actions';

interface CommunityAction {
  label: string;
  icon: React.ReactElement;
  onClick?: () => void;
  link?: string | null;
  disabled?: boolean;
  type?: 'destructive';
  key: string;
}

interface CommunityActionsGroup {
  key: string;
  items: CommunityAction[];
}

export async function leaveCommunity(userId: string | undefined, community: Community) {
  if (!userId) return;
  await leave(community?.subdomain, userId);
  await queryClient.invalidateQueries({ queryKey: communityKeys.userCommunities() });
}

export const useCommunityActions = ({
  community,
  user,
}: {
  community: Community;
  user?: CommunityMember;
}) => {
  const t = useTranslations('sidebar');
  const router = useRouter();

  const [, copy] = useCopyToClipboard();

  const { data: invite } = useReferralLink();

  return useMemo(() => {
    function copyInviteLink() {
      const inviteLink = invite?.id
        ? `${envConfig.appUrl}${getCommunityPath(community)}/invite/${invite.id}`
        : '';
      copy(inviteLink);
      toast(t('toast.copied'));
    }

    async function handleLeaveCommunity() {
      if (community.visibility === 'private') {
        return router.push('?show-leave=true');
      }

      await leaveCommunity(user?.id, community);
    }

    return [
      {
        key: 'community',
        items: [
          {
            key: 'community-settings',
            label: t('menu.community-settings'),
            disabled: !roleIsAtLeast(user?.role, 'admin'),
            icon: <GearLine />,
            link: `/cw/${community.subdomain}/settings/general`,
          },
          {
            key: 'community-information',
            label: t('menu.community-information'),
            icon: <BookOpenLine />,
            onClick: () => router.push('?show-info=true'),
          },
          {
            key: 'website',
            label: t('menu.website'),
            icon: <Link2Line />,
            link: community.website,
            disabled: !community.website,
          },
          {
            key: 'discord',
            label: t('menu.discord'),
            icon: <DiscordBrand />,
            link: community.discord,
            disabled: !community.discord,
          },
          {
            key: 'twitter',
            label: t('menu.twitter'),
            icon: <TwitterFilled />,
            link: community.twitter && getTwitterAccountURL(community.twitter),
            disabled: !community.twitter,
          },
        ],
      },
      {
        key: 'invite',
        items: [
          {
            key: 'invite',
            label: t('menu.invite'),
            icon: <EmailLine />,
            onClick: copyInviteLink,
            disabled: !user?.role,
          },
        ],
      },
      {
        key: 'destructive',
        items: [
          {
            key: 'report',
            label: t('menu.report'),
            icon: <FlagLine />,
            disabled: !user?.role || roleIsAtLeast(user?.role, 'editor'),
            onClick: () => router.push('?show-report=true'),
            type: 'destructive',
          },
          {
            key: 'leave',
            label: t('menu.leave'),
            icon: <LogOutLine />,
            disabled: !user?.role || roleIsAtLeast(user?.role, 'editor'),
            onClick: handleLeaveCommunity,
            type: 'destructive',
          },
        ],
      },
    ].map(group => ({
      ...group,
      items: group.items.filter(item => !item.disabled),
    })) as CommunityActionsGroup[];
  }, [community, copy, invite?.id, router, t, user]);
};
