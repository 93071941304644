'use client';

import React, { useMemo } from 'react';
import { cx } from 'class-variance-authority';
import { useRouter } from 'next/navigation';

import type { Community, CommunityMember } from '@zealy/queries';
import { Button } from '@zealy/design-system';
import { InfoLine } from '@zealy/icons';

import { useIsMobile } from '#hooks/useIsMobile';

import { styles } from '../../Sidebar.styles';
import { CommunityDropdown } from './CommunityDropdown';

export const CommunityHeader = ({
  community,
  user,
}: {
  community: Community;
  user?: CommunityMember;
}) => {
  const communityBannerImage = community?.image;

  const isMobile = useIsMobile();

  const style = useMemo(
    () => ({
      ...(communityBannerImage &&
        ({
          backgroundImage: `url(${communityBannerImage})`,
        } as React.CSSProperties)),
    }),
    [communityBannerImage],
  );

  const router = useRouter();

  return (
    <div
      data-testid="bannercontainer"
      data-part="bannercontainer"
      style={style}
      className={styles.banner.container()}
      onClick={isMobile ? () => router.push('?show-info=true') : undefined}
    >
      <div className={cx(styles.banner.content.wrapper({ withImage: !!communityBannerImage }))}>
        <p className="w-full min-w-0 truncate">
          <span className="body-component-lg-bold mr-50 text-primary">{community?.name}</span>
        </p>
        {isMobile ? (
          <Button variant="ghost" size="sm" onlyIcon leftIcon={<InfoLine />} />
        ) : (
          <CommunityDropdown community={community} user={user} />
        )}
      </div>
    </div>
  );
};
