'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Indicator, Root } from '@radix-ui/react-checkbox';
import * as R from '@radix-ui/react-radio-group';
import { forwardRef, useId } from 'react';
import { cn } from '../../utils/cn';
import { radioIndicatorStyles, radioItemContainerStyles, radioItemStyles, radioItemWrapperStyles, radioLabelStyles, } from './Radio.styles';
export const Group = ({ value, id: __id, children, className, ...props }) => {
    return _jsx(R.RadioGroup, { ...props, children: children });
};
export const GroupItem = forwardRef(({ value, id: __id, children, className, disabled: isDisabled, ...props }, ref) => {
    const id = __id || `radio:${useId()}`;
    return (_jsxs("label", { htmlFor: id, "data-component": "radio", "data-part": "wrapper", className: cn(radioItemWrapperStyles({ isDisabled }), className), children: [_jsx("div", { "data-part": "container", className: cn(radioItemContainerStyles({ isDisabled }), className), children: _jsx(R.RadioGroupItem, { "data-part": "item", className: cn(radioItemStyles({ isDisabled }), className), value: value, id: id, disabled: isDisabled, ...props, ref: ref, children: _jsx(R.RadioGroupIndicator, { "data-part": "indicator", className: radioIndicatorStyles() }) }) }), !!children && (_jsx("div", { "data-part": "label", className: cn(radioLabelStyles({ isDisabled })), children: children }))] }));
});
const Item = forwardRef(({ id: __id, children, className, disabled: isDisabled, ...props }, ref) => {
    const id = __id || `radio:${useId()}`;
    return (_jsxs("label", { htmlFor: id, "data-component": "radio", "data-part": "wrapper", className: cn(radioItemWrapperStyles({ isDisabled }), className), children: [_jsx("div", { "data-part": "container", className: cn(radioItemContainerStyles({ isDisabled }), className), children: _jsx(Root, { "data-part": "item", className: cn(radioItemStyles({ isDisabled }), className), id: id, disabled: isDisabled, ...props, ref: ref, children: _jsx(Indicator, { "data-part": "indicator", className: radioIndicatorStyles() }) }) }), !!children && (_jsx("div", { "data-part": "label", className: cn(radioLabelStyles({ isDisabled })), children: children }))] }));
});
export const Radio = {
    Group,
    GroupItem,
    Item,
};
