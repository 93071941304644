import { jsx as _jsx } from "react/jsx-runtime";
import { EmotionHappyFilled, EmotionNeutralFilled, EmotionSadFilled } from '@zealy/icons';
export const FEEDBACK_OPTIONS = [
    {
        value: 'bad',
        icon: _jsx(EmotionSadFilled, {}),
    },
    {
        value: 'neutral',
        icon: _jsx(EmotionNeutralFilled, {}),
    },
    {
        value: 'good',
        icon: _jsx(EmotionHappyFilled, {}),
    },
];
