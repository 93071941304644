import { useAuthenticatedUser } from '@zealy/queries';

import { envConfig } from '#app/config';

export const useIsCompanyMember = () => {
  const { data: user } = useAuthenticatedUser();

  if (user?.isMe) {
    const companyMember = user?.accounts?.find(account =>
      account.authentification.includes('@zealy.io'),
    );

    return Boolean(companyMember ?? envConfig.env === 'development');
  }

  return envConfig.env === 'development';
};
