import { CalendarEventLine, EmailLine, GiftLine, MapViewLine } from '@zealy/icons';

import { Step } from './Onboarding.types';

export const STEPS: Step[] = [
  {
    name: 'create-community',
    completed: true,
  },
  {
    name: 'create-quest',
    completed: false,
    link: '/questboard/admin/new',
    videoLink: 'https://zealy.io/docs/quests/create-quests#video',
    icon: <MapViewLine />,
    property: 'has3Quests',
  },
  {
    name: 'create-sprint',
    completed: false,
    link: '/leaderboard?sprintId=new',
    videoLink: 'https://zealy.io/docs/leaderboard/create-sprint#video',
    icon: <GiftLine />,
    property: 'hasSprint',
  },
  {
    name: 'invite-teammate',
    completed: false,
    link: '/settings/members',
    videoLink: 'https://zealy.io/docs/start-guide/manage-members',
    icon: <EmailLine />,
    property: 'hasCollaborators',
  },
  // {
  //   name: 'launch',
  //   completed: false,
  //   videoLink: 'https://zealy.io/docs/start-guide/community-setup#launch',
  //   icon: <CalendarEventLine />,
  //   property: 'launchDate',
  // },
];
