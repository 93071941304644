'use client';

import React from 'react';
import { cx } from 'class-variance-authority';
import Link from 'next/link';

import type { Community, CommunityMember } from '@zealy/queries';
import { Badge, TabItem, TabItemExpandable } from '@zealy/design-system';
import { useAuthenticatedUser } from '@zealy/queries';
import { roleIsAtLeast } from '@zealy/utils';

import { useCommunitiesFlags } from '#context/FeatureFlags/index';
import { useDidMount } from '#hooks/useDidMount';

import { Onboarding } from '../Onboarding';
import { CommunityHeader } from './components/CommunityHeader';
import { InboxPopover } from './components/InboxPopover';
import { PlanCard } from './components/PlanCard';
import { UserCard } from './components/UserCard';
import { useSidebar } from './Sidebar.hooks';
import { styles } from './Sidebar.styles';

export interface SidebarProps {
  className?: string;

  community: Community;
}

/**
 * The sidebar is a navigation element used on the questboard
 */
export const Sidebar = ({ community, ...props }: SidebarProps) => {
  const { data: user } = useAuthenticatedUser<CommunityMember>();

  const sidebarItems = useSidebar({
    user,
    community,
  });

  const { crm } = useCommunitiesFlags(['crm']);

  const hasLaunched = community?.launchDate && new Date(community?.launchDate) < new Date();

  const didMount = useDidMount();

  return (
    <nav data-section="sidebarnav" {...props} className={cx(styles.root(), props.className)}>
      <CommunityHeader community={community} user={user} />
      <div className="flex flex-col justify-between min-h-0 flex-1">
        <div className="flex flex-col p-tabs-list-y-md gap-tabs-list-md-y">
          {sidebarItems.map(({ expandable, badge, ...item }) =>
            item.value === 'notifications' && didMount ? (
              <InboxPopover key={item.value}>
                <TabItem size="md" as="button" key={item.value} {...item} data-navitem={item.value}>
                  {!!badge && <Badge size="xxs">{badge}</Badge>}
                </TabItem>
              </InboxPopover>
            ) : expandable ? (
              <TabItemExpandable
                as="button"
                data-navitem={item.value}
                size="md"
                key={item.value}
                collapsibleProps={{
                  open: item.selected ? true : undefined,
                }}
                {...item}
              >
                {item.children?.map(child => (
                  <Link href={child.href} key={child.value} prefetch={false}>
                    <TabItem
                      size="sm"
                      as="button"
                      key={child.value}
                      {...child}
                      data-navitem={child.value}
                    >
                      {!!badge && <Badge size="xxs">{badge}</Badge>}
                    </TabItem>
                  </Link>
                ))}
              </TabItemExpandable>
            ) : (
              <Link
                href={item.href}
                key={item.value}
                aria-disabled={item.disabled}
                prefetch={false}
              >
                <TabItem
                  size="md"
                  as="button"
                  key={item.value}
                  {...item}
                  data-navitem={item.value}
                  disabled={item.disabled ?? (item.value === 'members' && !crm.enabled)}
                >
                  {!!badge && <Badge size="xxs">{badge}</Badge>}
                </TabItem>
              </Link>
            ),
          )}
        </div>

        <div className="px-100 pb-100 flex flex-col gap-100">
          {!hasLaunched && roleIsAtLeast(user?.role, 'admin') && <Onboarding />}

          {hasLaunched && roleIsAtLeast(user?.role, 'admin') && didMount && (
            <PlanCard community={community} />
          )}

          {user && (
            <UserCard
              {...{
                user,
                subdomain: community.subdomain,
              }}
            />
          )}
        </div>
      </div>
    </nav>
  );
};
