import { useDebouncedEffect } from '@react-hookz/web';
import { useState } from 'react';

export const useDebouncedValue = <T,>(value: T, delay: number = 600) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useDebouncedEffect(() => setDebouncedValue(value), [value], delay);

  return debouncedValue;
};
