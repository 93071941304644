import { useMemo } from 'react';
export function assignRef(ref, value) {
    if (ref == null)
        return;
    if (typeof ref === 'function') {
        ref(value);
        return;
    }
    try {
        ref.current = value;
    }
    catch (error) {
        throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
    }
}
/**
 * Assigns all refs provided as arguments into a single ref variable. (Returns a function ref)
 * Use the returned value to bind to an HTML element or component.
 */
export function mergeRefs(...refs) {
    return (node) => {
        refs.forEach(ref => {
            assignRef(ref, node);
        });
    };
}
/**
 * React hook that merges all ref objects provided as arguments into a single ref variable. (Returns a function ref)
 * Use the returned value to bind to an HTML element or component. The other ref arguments can be used for
 * other internal purposes in the component you are authoring.
 *
 * @param refs
 * @returns
 */
export function useMergeRefs(...refs) {
    return useMemo(() => mergeRefs(...refs), refs);
}
