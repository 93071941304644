import * as Accordion from '@radix-ui/react-accordion';
import * as Collapsible from '@radix-ui/react-collapsible';
import React, { useMemo } from 'react';
import { useTranslations } from 'next-intl';

import { ProgressBar } from '@zealy/design-system';
import { ChevronDownLine } from '@zealy/icons';
import { useOnboardingSteps } from '@zealy/queries';

import { useDidMount } from '#hooks/useDidMount';

import { LaunchButton, LaunchCommunity } from './LaunchCommunity';
import { STEPS } from './Onboarding.constants';
import { OnboardingStep } from './OnboardingStep';

const OnboardingHeader = ({ nextStep, percentageDone, ...props }: any) => {
  const t = useTranslations('onboarding');

  return (
    <Collapsible.Trigger
      className="w-full flex flex-col gap-150 p-150 rounded-component-md hover:bg-component-quaternary-hover active:bg-component-quaternary-press transition-colors"
      {...props}
    >
      <div className="flex items-center gap-100 body-paragraph-sm-bold text-primary">
        <p>{t('title')}</p>
        <ChevronDownLine size={12} />
      </div>
      <ProgressBar
        size="sm"
        variant="brand"
        hideLabels
        value={percentageDone}
        max={100}
        fillShadowBg="var(--color-bg-popover)"
      />
      <p className="group-data-[state=closed]/onboarding:block hidden body-paragraph-sm">
        {t('description', {
          nextStep: nextStep.toLowerCase(),
          percentageDone,
        })}
      </p>
    </Collapsible.Trigger>
  );
};

export const Onboarding = () => {
  const t = useTranslations('onboarding.steps');
  const onboarding = useOnboardingSteps();

  const stepsWithStatus = useMemo(
    () =>
      STEPS.map(step => {
        if (step.property && onboarding.data && step.property in onboarding.data) {
          const hasModules = onboarding.data.hasModules;

          return {
            ...step,
            completed: Boolean(onboarding.data[step.property as keyof typeof onboarding.data]),
            link:
              step.name === 'create-quest' && !hasModules
                ? '/questboard/admin/module/new'
                : step.link,
          };
        }
        return step;
      }),
    [onboarding],
  );

  const activeStep = stepsWithStatus.find(({ completed }) => !completed);

  const percentageDone = useMemo(() => {
    const completedSteps = stepsWithStatus.filter(({ completed }) => completed).length;
    return Math.round((completedSteps / stepsWithStatus.length) * 100);
  }, [stepsWithStatus]);

  const didMount = useDidMount();

  if (!didMount) {
    return null;
  }

  return (
    <Collapsible.Root
      className="group/onboarding bg-popover rounded-component-md w-full overflow-hidden"
      defaultOpen
    >
      {activeStep && (
        <OnboardingHeader
          nextStep={t(`${activeStep.name}.label`)}
          percentageDone={percentageDone}
        />
      )}
      <Collapsible.Content className="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden data-[state=closed]:h-0 data-[state=open]:p-50">
        {onboarding.isLoading ? (
          <></>
        ) : (
          <Accordion.Root
            className="flex flex-col gap-50"
            type="single"
            defaultValue={activeStep?.name}
            collapsible
          >
            {stepsWithStatus.map(step => (
              <OnboardingStep active={activeStep?.name === step.name} key={step.name} {...step} />
            ))}
          </Accordion.Root>
        )}
      </Collapsible.Content>
      <LaunchCommunity launchDate={onboarding.data?.launchDate}>
        <LaunchButton />
      </LaunchCommunity>
    </Collapsible.Root>
  );
};
