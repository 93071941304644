'use client';

import React from 'react';
import { useCookie } from 'react-use';

import { Alert, Button } from '@zealy/design-system';
import { ArrowRightLine } from '@zealy/icons';

import { useCommunitiesFlags } from '#context/FeatureFlags';
import { useDidMount } from '#hooks/useDidMount';

interface FlagValue {
  title: string;

  description?: string;

  cta?: string;

  link?: string;

  variant?: 'info' | 'success' | 'warning' | 'error';
}

export const FeatureFlagBanner = () => {
  const { banner } = useCommunitiesFlags(['banner']);
  const didMount = useDidMount();
  const [seenBanner, setSeenBanner] = useCookie(`banner`);

  if (!banner.enabled || !didMount) return null;

  const { title, description, cta, link, variant } = JSON.parse(
    (banner.value as string) || '{}',
  ) as FlagValue;

  if (!title || seenBanner) return null;

  const handleClose = () => {
    setSeenBanner('true', {
      expires: 7,
    });
  };

  return (
    <Alert
      title={title}
      description={description}
      variant={variant}
      orientation="horizontal"
      onClose={handleClose}
      actions={
        cta ? (
          <Button variant="muted" size="sm" as="a" href={link} rightIcon={<ArrowRightLine />}>
            {cta}
          </Button>
        ) : undefined
      }
    />
  );
};
