'use client';

import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { AuthError, IAuthError } from '@zealy/utils';

const AuthErrorContext = createContext<{
  authError: IAuthError | undefined;
  setAuthError: (authError?: IAuthError) => void;
}>({
  authError: undefined,
  setAuthError: () => {},
});

const safeParse = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return undefined;
  }
};

export const AuthErrorProvider = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname();
  const search = useSearchParams();
  const router = useRouter();

  const authError = search.get('error');

  const parsedAuthError = authError ? safeParse(authError) : undefined;

  const instantiatedAuthError = parsedAuthError ? new AuthError(parsedAuthError) : undefined;

  const [error, setError] = useState<IAuthError | undefined>(instantiatedAuthError);

  const isConnected = !!Cookies.get('user_metadata');

  useEffect(() => {
    if (!pathname.includes('settings') && isConnected && authError)
      router.push(`/cw/_/settings/linked-accounts?error=${authError}`);
  }, [pathname, isConnected, router, authError]);

  return (
    <AuthErrorContext.Provider
      value={{
        authError: error,
        setAuthError: setError,
      }}
    >
      {children}
    </AuthErrorContext.Provider>
  );
};

export const useAuthError = () => {
  const result = useContext(AuthErrorContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};
