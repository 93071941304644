import type { MDXComponents } from 'mdx/types';
import * as React from 'react';
import { useMDXComponent } from 'next-contentlayer/hooks';
import Image from 'next/image';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Kbd,
  Link,
} from '@zealy/design-system';

import { Callout } from '#components/callout';
import { LevelCalculator } from '#components/LevelCalculator';
import { MdxCard } from '#components/mdx-card';
import { cn } from '#utils/utils';

const components: MDXComponents = {
  h1: ({ className, ...props }) => (
    <h1 className={cn('headings-headline-h3', className)} {...props} />
  ),
  h2: ({ className, ...props }) => (
    <h2 className={cn('headings-title-t1 first:mt-0', className)} {...props} />
  ),
  h3: ({ className, ...props }) => <h3 className={cn('headings-title-t2', className)} {...props} />,
  h4: ({ className, ...props }) => (
    <h4 className={cn('interface-sectionHeading', className)} {...props} />
  ),
  h5: ({ className, ...props }) => (
    <h5 className={cn('body-component-lg-bold', className)} {...props} />
  ),
  a: props => (
    <Link
      isExternal={props.href?.startsWith('http')}
      underlined
      size="lg"
      {...props}
      color="default"
    />
  ),
  p: ({ className, ...props }) => (
    <p className={cn('body-paragraph-lg text-secondary', className)} {...props} />
  ),
  ul: ({ className, ...props }) => (
    <ul className={cn('text-secondary body-paragraph-lg', className)} {...props} />
  ),
  ol: ({ className, ...props }) => (
    <ol className={cn('body-paragraph-lg text-secondary', className)} {...props} />
  ),
  blockquote: ({ className, ...props }) => (
    <blockquote className={cn('italic [&>*]:text-muted-foreground', className)} {...props} />
  ),
  img: ({ className, alt, ...props }: React.ImgHTMLAttributes<HTMLImageElement>) => (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      className={cn(
        'w-full rounded-component-md my-400 aspect-[3/2] object-cover object-top',
        className,
      )}
      alt={alt}
      {...props}
    />
  ),
  table: ({ className, ...props }: React.HTMLAttributes<HTMLTableElement>) => (
    <div className="my-300 w-full overflow-y-auto">
      <table className={cn('w-full', className)} {...props} />
    </div>
  ),
  tr: ({ className, ...props }: React.HTMLAttributes<HTMLTableRowElement>) => (
    <tr className={cn('m-0 border-t p-0 even:bg-muted', className)} {...props} />
  ),
  th: ({ className, ...props }) => (
    <th
      className={cn(
        'border px-200 py-100 text-left font-bold [&[align=center]]:text-center [&[align=right]]:text-right',
        className,
      )}
      {...props}
    />
  ),
  td: ({ className, ...props }) => (
    <td
      className={cn(
        'border px-200 py-100 text-left [&[align=center]]:text-center [&[align=right]]:text-right',
        className,
      )}
      {...props}
    />
  ),
  pre: ({ className, ...props }) => (
    <pre
      className={cn(
        'mb-200 mt-300 overflow-x-auto rounded-lg border bg-component-primary py-200',
        className,
      )}
      {...props}
    />
  ),
  code: ({ className, ...props }) => (
    <code
      className={cn(
        'relative rounded border-separators px-[0.3rem] py-[0.2rem] font-mono text-sm before:hidden after:hidden',
        className,
      )}
      {...props}
    />
  ),
  // @ts-ignore
  Image,
  Callout,
  Card: MdxCard,
  Kbd: ({ className, children, ref, ...props }) => (
    <Kbd className={className} {...props}>
      {children}
    </Kbd>
  ),
  Accordion: ({ ref, ...props }) => <Accordion className="not-prose" collapsible {...props} />,
  AccordionItem: ({ ref, children, title, description, ...props }) => (
    <AccordionItem value={title} {...props}>
      <AccordionTrigger>{title}</AccordionTrigger>
      <AccordionContent>{description}</AccordionContent>
    </AccordionItem>
  ),
  LevelCalculator,
};

interface MdxProps {
  code: string;
  overwriteComponents?: MDXComponents;
}

export function Mdx({ code, overwriteComponents }: MdxProps) {
  const Component = useMDXComponent(code);

  return (
    <div className="mdx">
      <Component
        components={overwriteComponents ? { ...components, ...overwriteComponents } : components}
      />
    </div>
  );
}
